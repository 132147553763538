<template>
  <div class="mt-20 d-flex sms">
    <div class="d-flex block">
      <div class="block__item">
        <span class="select-label">Тема письма</span>
        <input
          type="text"
          placeholder="Напишите тему письма"
          class="filter-input"
          v-model="filters.theme"
          :class="
            errorsNeedToShow && isFieldEmpty(filters.theme) ? 'error' : ''
          "
        />
      </div>
    </div>

    <div
      v-for="(item, idx) in filters.periods"
      class="block mt-10 d-flex"
      :key="idx"
    >
      <div class="block__item">
        <span v-if="idx === 0" class="select-label"
          >Задать периодичность отправки</span
        >
        <v-select
          v-model="item.period"
          :options="periodOptions"
          placeholder="Выберите периодичность отправки"
          class="select modal-select"
          :reduce="(value) => value.id"
          :class="errorsNeedToShow && isFieldEmpty(item.period) ? 'error' : ''"
          label="value"
        >
          <template v-slot:no-options>
            <div>Извините, ничего не найдено</div>
          </template>
        </v-select>
      </div>
      <div class="block__item">
        <span v-if="idx === 0" class="select-label">Содержание</span>
        <input
          type="text"
          placeholder="Напишите тему письма"
          class="filter-input"
          v-model="item.content"
          :class="errorsNeedToShow && isFieldEmpty(item.content) ? 'error' : ''"
        />
      </div>
      <div v-if="idx !== 0" @click="removeItem(idx)" class="delete-item">
        <mdicon size="24" name="close" class="delete-icon" />
      </div>
    </div>

    <div v-if="!isPeriodsMax" class="d-flex justify-end">
      <div @click="addItem" class="text-button">Добавить рассылку</div>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, watch } from 'vue';

const emit = defineEmits(['filters-change']);

const props = defineProps({
  errorsNeedToShow: Boolean,
  warnings: Object,
});

const periodOptions = ref([
  { id: 1, value: '> 1 дня' },
  { id: 3, value: '> 3 дней' },
  { id: 7, value: '> 1 недели' },
  { id: 31, value: '> 1 месяца' },
]);

const isPeriodsMax = ref(props.warnings?.periods.length === 4 ? true : false);

const filters = reactive({
  theme: props.warnings?.theme ?? '',
  periods: props.warnings?.periods ?? [{ period: '', content: '' }],
});

const addItem = () => {
  filters.periods.push({ period: '', content: '' });
  if (filters.periods.length === 4) {
    isPeriodsMax.value = true;
  }
};

const removeItem = (index) => {
  filters.periods.splice(index, 1);
  isPeriodsMax.value = false;
};

const isFieldEmpty = (value) => {
  if (value) return false;
  else return true;
};

const emitFiltersChange = () => {
  emit('filters-change', filters);
};

watch(filters, emitFiltersChange, { deep: true });
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_colors';

.delete-item {
  position: absolute;
  top: 8%;
  right: 4px;
  width: 26px;
  height: 26px;
  background-color: map-get($lightgray-stroke, 'normal');
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete-icon {
  color: var(--white);
}

.error {
  border-color: red !important;
}

.select {
  font-size: 14px !important;
}
.select::placeholder {
  font-size: 14px !important;
}

.checkboxes {
  margin: 30px 0;

  &__item {
    margin: 0;
    width: 200px;
  }
}

.select-label {
  font-family: 'Golos' !important;
  font-size: 12px;
  margin-bottom: 9px;
  display: block;
}

.justify-end {
  margin-top: 5px;
  justify-content: end;
}

.text-button {
  font-size: 13px;
  font-weight: 500;
  color: map-get($primary-text, 'hover');
  cursor: pointer;
}

.sms {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.block {
  position: relative;
  width: 100%;
}

.block__item:nth-child(1) {
  margin-right: 10px;
  width: 40%;
}

.block__item:nth-child(2) {
  width: 60%;
}

.second-block {
  width: 100%;
}

.filter-input {
  font-family: 'Golos' !important;
  margin-bottom: 15px;
  height: 34px;
  font-size: 14px !important;
  width: 100%;
  margin-right: 20px;
  border: 1px solid map-get($lightgray-stroke, 'normal');
  border-radius: 10px;
  padding: 0 0 0 10px;
}
.filter-input::placeholder {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.9;
}

.filter-textarea {
  width: 100% !important;
  padding-top: 5px;
  margin: 0;
  font-family: 'Golos' !important;
  height: 175px;
  resize: none;
}
</style>

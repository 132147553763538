<template>
  <base-popup
    size="large"
    topIndent="80"
    :is-visible="isModalVisible"
    title="Фильтр должников"
    subtitle=""
    @close="onModalClose"
  >
    <div class="d-flex block">
      <div class="block__item">
        <span class="select-label">Название ЖК</span>
        <v-select
          v-model="filters.complexId"
          :options="complexOptions"
          placeholder="Выберите ЖК"
          class="debtors-modal__select-block-select modal-select"
          :reduce="(value) => value.id"
          label="value"
        >
          <template v-slot:no-options>
            <div>Извините, ничего не найдено</div>
          </template>
        </v-select>
      </div>
      <div class="block__item">
        <span class="select-label">Длительность задолженности</span>
        <v-select
          v-model="filters.debtDur"
          :options="debthDurationOptions"
          placeholder="Выберите длительность"
          class="debtors-modal__select-block-select modal-select"
          :reduce="(value) => value.id"
          label="value"
        >
          <template v-slot:no-options>
            <div>Извините, ничего не найдено</div>
          </template>
        </v-select>
      </div>
    </div>

    <div class="d-flex block">
      <div class="block__item">
        <span class="select-label">Дом</span>
        <v-select
          v-model="filters.buildingId"
          :options="buildingOptions"
          placeholder="Дом*"
          class="debtors-modal__select-block-select modal-select"
          :reduce="(value) => value.id"
          label="value"
        >
          <template v-slot:no-options>
            <div v-if="!filters.complexId">Сначала выберите комплекс</div>
            <div v-else>Извините, ничего не найдено</div>
          </template>
        </v-select>
      </div>
      <div class="block__item">
        <span class="select-label">Сумма задолжености от (руб.)</span>
        <v-select
          v-model="filters.debtSum"
          :options="debthSumOptions"
          placeholder="Выберите сумму"
          class="debtors-modal__select-block-select modal-select"
          :reduce="(value) => value.id"
          label="value"
        >
          <template v-slot:no-options>
            <div>Извините, ничего не найдено</div>
          </template>
        </v-select>
      </div>
    </div>

    <div class="d-flex block">
      <div class="block__item">
        <span class="select-label">Кол-во уведомлений</span>
        <v-select
          v-model="filters.warnsCount"
          :options="warnsCountOptions"
          placeholder="Выберите кол-во"
          class="debtors-modal__select-block-select modal-select"
          :reduce="(value) => value.id"
          label="value"
        >
          <template v-slot:no-options>
            <div>Извините, ничего не найдено</div>
          </template>
        </v-select>
      </div>
      <div class="block__item">
        <!-- <span class="select-label">Тип уведомления</span>
          <v-select
            v-model="filters.warnsCount"
            :options="messageTypeOptions"
            placeholder="Выберите тип"
            class="debtors-modal__select-block-select modal-select"
            :reduce="(value) => value.id"
            label="value"
          >
            <template v-slot:no-options>
              <div>Извините, ничего не найдено</div>
            </template>
          </v-select> -->
      </div>
    </div>

    <div class="line"></div>

    <div class="d-flex justify-content-between align-items-center">
      <div v-if="filteredUsers">
        <span v-if="allFiltersEmpty" class="select-label"
          >Всего {{ filteredUsers }} пользователей</span
        >
        <span v-else class="select-label"
          >Выбрано {{ filteredUsers }} пользователей</span
        >
      </div>
      <div v-else />

      <div>
        <base-button
          text="Отмена"
          color="gray"
          class="mr-3"
          @click.prevent="onModalClose"
        />

        <base-button text="Сохранить" color="green" @click="onFormSubmit" />
      </div>
    </div>
  </base-popup>
</template>
<script setup>
import {
  defineEmits,
  defineProps,
  reactive,
  ref,
  watch,
  computed,
  onMounted,
} from 'vue';
import { BasePopup, BaseButton } from '@/components/atoms/';
import { useQuery } from '@vue/apollo-composable';
import { getAllComplexes, getBuildings } from '@/graphql/Claims.graphql';

const emit = defineEmits(['close', 'onSubmitFilter']);

const props = defineProps({
  isModalVisible: {
    type: Boolean,
    default: false,
  },
  filtersData: Object,
  filteredDebtors: Number,
  debtorsListRules: Object,
});

const complexOptions = ref([]);
const buildingOptions = ref([]);
const storedComplexId = ref(
  props.filtersData.complexId === 0 ? '' : props.filtersData.complexId
);

const warnsCountOptions = ref([]);
const messageTypeOptions = ref([{ id: 1, value: '1' }]);

let filters = reactive({
  complexId:
    props.filtersData.complexId === 0 ? '' : props.filtersData.complexId,
  buildingId:
    props.filtersData.buildingId === 0 ? '' : props.filtersData.buildingId,
  debtDur: props.filtersData.debtDur === 0 ? '' : props.filtersData.debtDur,
  debtSum: props.filtersData.debtSum === 0 ? '' : props.filtersData.debtSum,
  warnsCount:
    props.filtersData.warnsCount === 0 ? '' : props.filtersData.warnsCount,
});
const allFiltersEmpty = ref(
  Object.values(filters).every((value) => value === '')
);

const { result: allComplexes } = useQuery(getAllComplexes);
const { result: allBuildings, refetch: refetchBuildings } = useQuery(
  getBuildings,
  { complexId: filters.complexId }
);

const filteredUsers = computed(() => {
  return props.filteredDebtors;
});

watch(allComplexes, (value) => {
  complexOptions.value = value.getAllComplexes.map(({ id, name }) => {
    return { id, value: name };
  });
});

watch(allBuildings, (value) => {
  buildingOptions.value = value.getBuildings.map(({ id, name }) => {
    return { id, value: name };
  });
});

const onFormSubmit = () => {
  const updatedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
    acc[key] = value === '' ? 0 : value;
    return acc;
  }, {});

  emit('onSubmitFilter', updatedFilters);
  onModalClose();
};

const onModalClose = () => {
  emit('close');
};

watch(
  () => props.filtersData,
  async (newValue) => {
    const updatedFilters = Object.entries(newValue).reduce(
      (acc, [key, value]) => {
        acc[key] = value === 0 ? '' : value;
        return acc;
      },
      {}
    );

    filters = updatedFilters;
  },
  { deep: true }
);

watch(
  filters,
  async (value) => {
    if (value.complexId !== storedComplexId.value) {
      filters.buildingId = '';
      storedComplexId.value = value.complexId;
      buildingOptions.value = [];

      const {
        data: { getBuildings },
      } = await refetchBuildings({
        complexId: value.complexId,
      });

      buildingOptions.value = await getBuildings.map(({ id, name }) => {
        return {
          id,
          value: name,
        };
      });
    }

    if (!value.complexId) {
      filters.buildingId = '';
      buildingOptions.value = [];
    }
    allFiltersEmpty.value = Object.values(filters).every(
      (value) => value === ''
    );
  },
  { deep: true }
);

const debthSumOptions = computed(() => {
  let arr = [];
  let i = Number(props.debtorsListRules.amountFrom) ?? 500;

  for (i; i <= 1000000; i += 500) {
    arr.push({ id: i, value: `${i}` });
  }
  return arr;
});

const debthDurationOptions = computed(() => {
  let arr = [
    { id: 10, value: '10 Дней' },
    { id: 20, value: '20 Дней' },
  ];

  for (let i = 1; i <= 36; i++) {
    arr.push({ id: i * 30.5, value: `${i} мес.` });
  }
  return arr;
});

onMounted(() => {
  for (let i = 1; i <= 50; i++) {
    warnsCountOptions.value.push({ id: i, value: `${i}` });
  }
});
</script>

<style scoped lang="scss">
.block {
  gap: 12px;
  margin-bottom: 15px;

  &__item {
    width: 100%;
  }
}

.select-label {
  font-size: 12px;
  margin-bottom: 9px;
  display: block;
}

.line {
  margin: 30px 0;
  width: 100%;
  height: 1px;
  background-color: #00000047;
}
</style>

<template>
  <!-- @submit.prevent="logInClient" -->
  <form
    v-if="!antiSpamControl"
    @submit.prevent="handleSubmit"
    class="form sms-form"
  >
    <p class="sms-form__title">Введите код из СМС</p>
    <div class="sms-form__number" style="color: var(--grey-color)">
      <mdicon name="cellphone" size="20" class="icon-phone" />
      {{ phone }}
    </div>
    <div class="d-flex sms-form__inputs">
      <input
        v-for="(item, index) in inputs"
        :key="index"
        ref="inputRefs"
        maxlength="1"
        @keyup="focusNextInput(index, $event)"
        @keydown="handleBackspace(index, $event)"
        @focus="isError = false"
        class="sms-form__inputs-item"
        :class="{ 'sms-form__inputs-item--error': isError }"
      />
    </div>
    <div
      class="sms-form__action mt-40 justify-content-center align-items-center"
    >
      <div class="text-center">
        <base-button
          text="Войти"
          type="submit"
          :disabled="isInputsEmpty"
          color="green"
          size="small"
        />
      </div>
      <div class="sms-form__action-timer">
        <div
          v-if="!timeExpired"
          class="sms-form__action-timer-numbers"
          style="color: var(--grey-color)"
        >
          {{ timeLeft }}
        </div>
        <div
          @click="timeExpired ? resendSMS() : () => {}"
          style="cursor: pointer"
          :style="{
            color: timeExpired ? 'var(--primary-color)' : 'var(--grey-color)',
          }"
        >
          Отправить код повторно
        </div>
      </div>
    </div>
  </form>
  <p v-else class="sms-form__title">
    Превышено допустимое количество запросов,<br />
    попробуйте через некоторое время
  </p>
</template>

<script setup>
import { BaseButton } from '@/components/atoms';
import { ref, reactive, onMounted, onUnmounted, watchEffect, watch } from 'vue';
import { ory } from '@/config/ory';
import { logIn as Login, sendSMS, oryLogIn } from '@/graphql/Login.graphql';
import { useMutation } from '@vue/apollo-composable';
import { useRouter } from 'vue-router';
import { logErrorMessages } from '@vue/apollo-util';
import { useStore } from 'vuex';
import { cleanPhoneNumber } from '@/utils/phoneMask';
import { decryptString, encryptString } from '@/utils/timeCrypt';
import { EventBus } from '@/utils/eventBus';

// TODO: make the remove focus on "Enter"
const store = useStore();

const props = defineProps({
  phone: { type: String, required: true },
  flow: Object,
});

const router = useRouter();

const session = ref();
const inputs = reactive(Array(5).fill(''));
const inputRefs = ref([]);
const isInputsEmpty = ref(true);
const isError = ref(false);
const initialTime = ref(30);
const timeLeft = ref(initialTime.value);
const timeExpired = ref(false);
const intervalId = ref(null);
const antiSpamControl = ref(null);
let cryptoKey = 539;

const { mutate: sendSMSMutation } = useMutation(sendSMS);
const { mutate: oryLogInMutate, onError: errorOryLogIn } = useMutation(
  oryLogIn,
  () => ({
    variables: { data: {} },
  })
);

const getApiLink = () => {
  if (localStorage.getItem('apiUrl')) {
    return localStorage.getItem('apiUrl');
  }
  //   const storedData = store.getters['app/getSession']
  const storedData = localStorage.getItem('s');

  if (!storedData) return;

  const data = JSON.parse(storedData);
  const tenants = data.identity.metadata_public.tenants;
  const activeTenant = tenants.active;
  const tenantInfo = tenants.available.find(
    (tenant) => tenant.id === activeTenant
  );

  if (!tenantInfo) return;

  return tenantInfo.apiUrl;
};

const oryLoginFunc = async (resp) => {
  let apiLink = getApiLink();
  // console.log(apiLink, 'apiLink');

  try {
    const response = await fetch(`${apiLink}/graphql`, {
      headers: {
        Authorization: `Bearer ${resp.session_token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        operationName: 'oryLogIn',
        variables: {
          data: {},
        },
        query:
          'mutation oryLogIn($data: OryLoginInput!) {\n  oryLogIn(data: $data) {\n    token\n    role\n    __typename\n  }\n}\n',
      }),
      method: 'POST',
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error(`HTTP error status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error('Произошла ошибка при выполнении запроса:', error);
  }
};

const handleSubmit = async () => {
  try {
    const response = await fetch(`https://trouter.domion.tech/tenant/login`, {
      // const response = await fetch(`${config.VUE_APP_API_TROUTER}/tenant/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phoneNumber: cleanPhoneNumber(props.phone),
        code: inputs.join(''),
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error status: ${response.status}`);
    } else {
      const data = await response.json();
      let session = data.session;

      if (!session) {
        await router.push('/client/message?user-not-found');
        return;
      }

      if (!session.identity.metadata_public.tenants.available.length) {
        localStorage.setItem('session_token', data.session_token);
        await store.dispatch('app/setSession', session);

        await store.dispatch('app/setAuth', {
          token: 'setup',
          role: 'CLIENT',
          user: '{}',
        });

        await router.push('/app/setup-client/main');
        window.location.reload();
        return;
      }

      localStorage.setItem('session_token', data.session_token);
      await store.dispatch('app/setSession', session);
      EventBus.emit('set-graphql-link');

      const { data: sessionData } = await oryLoginFunc(data);

      await store.dispatch('app/setAuth', {
        token: sessionData.oryLogIn.token,
        role: sessionData.oryLogIn.role,
        user: '{}',
      });

      await router.push('/app/client/main');
      window.location.reload();
    }

    return response;
  } catch (error) {
    store.dispatch('notification/showNotification', {
      text: 'Введенные данные некорректны. Пожалуйста, проверьте номер телефона и СМС-код и попробуйте снова.',
      type: 'error',
    });
    // console.error('There was a problem with the fetch operation:', error);
    const err = localStorage.getItem('errorCli');
    if (error && !err) window.location.reload();
    localStorage.setItem('errorCli', error);
  }
};

const startTimer = () => {
  if (intervalId.value !== null) {
    clearInterval(intervalId.value);
  }

  intervalId.value = setInterval(() => {
    timeLeft.value -= 1;
    if (timeLeft.value <= 0) {
      stopTimer();
    }
  }, 1000);
};

const stopTimer = () => {
  clearInterval(intervalId.value);
  intervalId.value = null;
  timeExpired.value = true;
};

async function getCode(phoneNumber) {
  try {
    const response = await fetch(
      `${config.VUE_APP_API_TROUTER}/tenant/get-code`,
      // `https://trouter.domion.tech/tenant/get-code`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber: cleanPhoneNumber(phoneNumber) }),
      }
    );

    if (!response.ok) {
      store.dispatch('notification/showNotification', {
        text: 'Ошибка отправки СМС кода, попробуйте еще раз',
        type: 'error',
      });
      throw new Error(`HTTP error status: ${response.status}`);
    }

    return response;
  } catch (error) {
    store.dispatch('notification/showNotification', {
      text: 'Ошибка отправки СМС кода, попробуйте еще раз',
      type: 'error',
    });
    // console.error('There was a problem with the fetch operation:', error);
  }
}

const resendSMS = async () => {
  timeLeft.value = initialTime.value;
  timeExpired.value = false;
  startTimer();

  getCode(cleanPhoneNumber(props.phone))
    .then((data) => {
      if (!data.ok) {
        store.dispatch('notification/showNotification', {
          text: 'Ошибка, возможно такого номера не существует, попробуйте еще раз',
          type: 'error',
        });
        return;
      }
    })
    .catch((error) => () => {}); // console.error(error));
  // const { data } = await sendSMSMutation({
  //     phone: cleanPhoneNumber(props.phone)
  // });

  // if (!data.sendSMS) {
  //     store.dispatch('notification/showNotification', {
  //         text: 'Ошибка, возможно такого номера не существует, попробуйте еще раз',
  //         type: 'error',
  //     });
  // }

  // // console.log('data', { data });
};

watchEffect(() => {
  if (!timeExpired.value) {
    startTimer();
  }
});

const focusNextInput = (index, event) => {
  if (event.key !== 'Backspace' && event.key !== 'Delete') {
    inputs[index] = event.target.value;

    const currentInput = inputRefs.value[index];
    if (currentInput.value && index < inputRefs.value.length - 1) {
      inputRefs.value[index + 1].focus();
    }

    isInputsEmpty.value = inputs.some((str) => str === '');
  }
};

const handleBackspace = (index, event) => {
  if (event.key === 'Backspace' || event.key === 'Delete') {
    inputs[index] = '';
    isInputsEmpty.value = inputs.some((str) => str === '');

    if (index > 0) {
      setTimeout(() => {
        inputRefs.value[index - 1].focus();
      }, 10);
    }
  }
};

const userBlock = () => {
  // If user send to many requests
  antiSpamControl.value = true;
  const date = new Date();
  date.setMinutes(date.getMinutes() + 15);

  const newFingerprint = createDateFingerprint(date);
  const encryptedNewFingerprint = encryptString(newFingerprint, cryptoKey);
  localStorage.setItem('t', encryptedNewFingerprint);
};

const logInClient = () => {
  // logIn();
  ory
    .updateLoginFlow({
      flow: String(props.flow.id),
      updateLoginFlowBody: {
        csrf_token: props.flow.ui.nodes[0].attributes.value,
        identifier: cleanPhoneNumber(props.phone),
        method: 'password',
        password: inputs.join(''),
      },
    })
    .then((res) => {
      // console.log(res, 'res');
      // if (flow?.return_to) {
      //     window.location.href = flow?.return_to
      //     return
      // }
      // router.push("/")
    });
};

const { mutate: logIn, onError } = useMutation(Login, () => ({
  variables: {
    data: {
      email: cleanPhoneNumber(props.phone),
      password: inputs.join(''),
    },
  },
  update: async (cache, { data }) => {
    localStorage.removeItem('t');

    await store.dispatch('app/setAuth', {
      token: data.logIn.token,
      role: data.logIn.role,
      user: '{}',
    });

    await router.push('/app/client/main');
    location.reload();
  },
}));

onError((error) => {
  isError.value = true;

  store.dispatch('notification/showNotification', {
    text: 'Ошибка! Введенный код неверный',
    type: 'error',
  });
  logErrorMessages(error);

  if (localStorage.getItem('tc')) {
    let tries = Number(localStorage.getItem('tc'));
    tries += 1;
    localStorage.setItem('tc', `${tries}`);

    if (tries >= 3) {
      userBlock();
    }
  } else {
    let firstTry = 1;
    localStorage.setItem('tc', `${firstTry}`);
  }
});

const createDateFingerprint = (date) => {
  return date.toISOString();
};

const parseDateFingerprint = (fingerprint) => {
  return new Date(fingerprint);
};

onMounted(async () => {
  inputRefs.value[0].focus();

  if (localStorage.getItem('t')) {
    const encrypted = localStorage.getItem('t');
    const decryptedFingerprint = decryptString(encrypted, cryptoKey);
    const parsedDate = parseDateFingerprint(decryptedFingerprint);

    const now = new Date();
    const differenceInMilliseconds = now.getTime() - parsedDate.getTime();

    if (differenceInMilliseconds >= 0) {
      // Сохраненное время уже прошло.
      antiSpamControl.value = false;
    } else {
      // Сохраненное время еще не наступило.
      antiSpamControl.value = true;
    }
  } else {
    antiSpamControl.value = false;
    const date = new Date();
    const fingerprint = createDateFingerprint(date);
    const encryptedFingerprint = encryptString(fingerprint, cryptoKey);
    localStorage.setItem('t', encryptedFingerprint);
  }
});

onUnmounted(() => {
  stopTimer();
});
</script>

<style scoped lang="scss">
.sms-form {
  text-align: left;

  &__title {
    margin-bottom: 20px;
    color: var(--primary-color);
    font-size: 20px;
    line-height: 24px;
    opacity: 0.5;
  }

  &__number {
    margin-bottom: 10px;
  }

  &__inputs {
    gap: 5px;

    &-item {
      width: 25%;
      height: 48px;
      background: rgba(242, 244, 246, 1);
      border-radius: 15px;
      border: 1px solid rgba(209, 207, 215, 1);

      text-align: center;
      font-size: 18px;
      font-weight: 500;
      color: var(--black);

      &:focus {
        background: var(--primary-light-opacity-color);
        border: 1px solid var(--primary-color);
      }

      &--error {
        border: 1px solid var(--button-error-color);
        background: rgba(245, 57, 57, 0.15);
      }
    }
  }

  &__action {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-timer {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-numbers {
        margin-bottom: 10px;
      }
    }
  }
}
</style>

<template>
  <base-popup
    size="largest"
    topIndent="80"
    :is-visible="isModalVisible"
    title="Сформировать уведомление"
    subtitle=""
    @close="onModalClose"
  >
    <div class="mb-10">Выберите способ уведомления</div>

    <base-accordion
      header="SMS"
      :withCheckbox="true"
      @checked="onCheck($event, 'sms')"
      :isOpen="isOpen"
      class="accordion-item"
    >
      <div class="accordion__content">
        <div class="accordion__content-row">
          <div class="accordion__content-row-title">Название рассылки:</div>
          <base-input
            type="text"
            placeholder="Название рассылки"
            class="accordion__content-row-input"
            :value="notificationData.sms.title"
            @input="onChangeInputValue($event, 'sms', 'title')"
          ></base-input>
        </div>
        <div class="accordion__content-row">
          <div class="accordion__content-row-title">Содержание:</div>
          <base-textarea
            placeholder="Содержание"
            class="accordion__content-row-textarea"
            :value="notificationData.sms.content"
            maxLength="450"
            @input="onChangeInputValue($event, 'sms', 'content')"
          ></base-textarea>
        </div>
      </div>
    </base-accordion>
    <base-accordion
      header="Пуш-уведомление"
      :withCheckbox="true"
      @checked="onCheck($event, 'push')"
      :isOpen="isOpen"
      class="accordion-item"
    >
      <div class="accordion__content">
        <div class="accordion__content-row">
          <div class="accordion__content-row-title">Название рассылки:</div>
          <base-input
            type="text"
            placeholder="Название рассылки"
            class="accordion__content-row-input"
            :value="notificationData.push.title"
            @input="onChangeInputValue($event, 'push', 'title')"
          ></base-input>
        </div>
        <div class="accordion__content-row">
          <div class="accordion__content-row-title">Содержание:</div>
          <base-textarea
            placeholder="Содержание"
            class="accordion__content-row-textarea"
            :value="notificationData.push.content"
            @input="onChangeInputValue($event, 'push', 'content')"
            maxLength="450"
          ></base-textarea>
        </div>
      </div>
    </base-accordion>
    <base-accordion
      header="E-mail"
      :withCheckbox="true"
      @checked="onCheck($event, 'email')"
      :isOpen="isOpen"
      class="accordion-item"
    >
      <div class="accordion__content">
        <div class="accordion__content-row">
          <div class="accordion__content-row-title">Тема письма:</div>
          <base-input
            type="text"
            placeholder="Тема письма"
            class="accordion__content-row-input"
            :value="notificationData.email.title"
            @input="onChangeInputValue($event, 'email', 'title')"
          ></base-input>
        </div>
        <div class="accordion__content-row">
          <div class="accordion__content-row-title">Содержание:</div>
          <base-textarea
            placeholder="Содержание"
            class="accordion__content-row-textarea"
            :value="notificationData.email.content"
            @input="onChangeInputValue($event, 'email', 'content')"
            maxLength="450"
          ></base-textarea>
        </div>
      </div>
    </base-accordion>
    <base-accordion
      header="Звонки"
      :withCheckbox="true"
      @checked="onCheck($event, 'call')"
      :isOpen="isOpen"
      class="accordion-item"
    >
      <div class="accordion__content">
        <div class="accordion__content-row">
          <div class="accordion__content-row-title">Содержание:</div>
          <base-textarea
            placeholder="Содержание"
            class="accordion__content-row-textarea"
            :value="notificationData.call.content"
            @input="onChangeInputValue($event, 'call', 'content')"
            maxLength="450"
          ></base-textarea>
        </div>
      </div>
    </base-accordion>

    <div class="line"></div>

    <div class="d-flex justify-content-end">
      <div class="d-flex">
        <base-button
          text="Отмена"
          color="gray"
          class="mr-3"
          @click.prevent="onModalClose"
        />

        <base-button text="Отправить" color="green" @click="onFormSubmit" />
      </div>
    </div>
  </base-popup>
</template>
<script setup>
import { defineProps, defineEmits, reactive, ref, watch, onMounted } from 'vue';
import {
  BasePopup,
  BaseButton,
  BaseInput,
  BaseTextarea,
} from '@/components/atoms';
import BaseAccordion from '../atoms/components/BaseAccordion.vue';
import { useStore } from 'vuex';

const emit = defineEmits(['close', 'submit']);

const props = defineProps({
  isModalVisible: {
    type: Boolean,
    default: false,
  },
  users: Array,
  debtorsSettings: Object,
});

const store = useStore();

const isOpen = ref(false);
let notificationData = reactive({
  sms: {
    title: props.debtorsSettings?.sms.theme,
    content: props.debtorsSettings?.sms.periods[0].content,
    checked: false,
  },
  push: {
    title: props.debtorsSettings?.push.theme,
    content: props.debtorsSettings?.push.periods[0].content,
    checked: false,
  },
  email: {
    title: props.debtorsSettings?.email.theme,
    content: props.debtorsSettings?.email.periods[0].content,
    checked: false,
  },
  call: {
    content: props.debtorsSettings?.call.periods[0].content,
    checked: false,
  },
});

const onChangeInputValue = (e, title, field) => {
  let value = e.target.value;
  notificationData[title][field] = value;
};

const onCheck = (value, name) => {
  if (value) notificationData[name].checked = true;
  else notificationData[name].checked = false;
};

const onModalClose = (isSubmit) => {
  isSubmit !== true ? (isSubmit = false) : (isSubmit = true);
  let isClosed = true;
  if (!isSubmit) {
    isClosed = confirm(
      'Вы действительно хотите закрыть окно? Внесенные изменения не сохранятся'
    );
  }
  if (isClosed) {
    notificationData = {
      sms: {
        title: props.debtorsSettings?.sms.theme,
        content: props.debtorsSettings?.sms.periods[0].content,
        checked: false,
      },
      push: {
        title: props.debtorsSettings?.push.theme,
        content: props.debtorsSettings?.push.periods[0].content,
        checked: false,
      },
      email: {
        title: props.debtorsSettings?.email.theme,
        content: props.debtorsSettings?.email.periods[0].content,
        checked: false,
      },
      call: {
        content: props.debtorsSettings?.call.periods[0].content,
        checked: false,
      },
    };
    emit('close');
  }
};

const validateNotificationData = (notificationData) => {
  let result = {};
  let errorFields = {};

  for (let key in notificationData) {
    if (notificationData[key] && typeof notificationData[key] === 'object') {
      if (notificationData[key].checked) {
        if (key === 'call') {
          if (notificationData[key].content !== '') {
            result[key] = {
              content: notificationData[key].content,
            };
          } else {
            errorFields[key] = {
              content: notificationData[key].content,
            };
          }
        } else if (
          notificationData[key].title === '' ||
          notificationData[key].content === ''
        ) {
          errorFields[key] = {
            title: notificationData[key].title,
            content: notificationData[key].content,
          };
        } else {
          result[key] = {
            title: notificationData[key].title,
            content: notificationData[key].content,
          };
        }
      }
    } else {
      delete notificationData[key];
    }
  }

  if (Object.keys(errorFields).length > 0) {
    return { type: 'error', ...errorFields };
  }

  return result;
};

const getErrorMessage = (errorObject) => {
  const { type, ...fields } = errorObject;

  const decodeKey = (key) => {
    switch (key) {
      case 'sms':
        return 'SMS';
      case 'push':
        return 'Пуш-уведомление';
      case 'email':
        return 'Email';
      case 'call':
        return 'Звонки';
      default:
        return '';
    }
  };
  const messages = Object.keys(fields).map(decodeKey).filter(Boolean);
  return messages.join(', ');
};

const onFormSubmit = () => {
  let result = validateNotificationData(notificationData);

  if (Object.entries(result).length === 0 || result.type) {
    if (Object.entries(result).length === 0) {
      store.dispatch('notification/showNotification', {
        type: 'error',
        text: 'Выберите как минимум 1 пункт',
      });
      return;
    }
    store.dispatch('notification/showNotification', {
      type: 'error',
      text: `Есть незаполненные поля в ${getErrorMessage(result)}`,
    });
    return;
  }

  let debtorsIds = props.users.map((user) => user.id);
  let data = {
    ...result,
    ...(result.call && { call: result.call.content }),
    debtorsIds,
  };
  emit('submit', data);
  onModalClose(true);
  // isOpen.value = false;
};

watch(
  () => props.isModalVisible,
  (value) => {
    if (value) {
      setTimeout(() => {
        isOpen.value = true;
      }, 500);
      console.log(notificationData);
    }
  },
  { deep: true }
);
</script>

<style scoped lang="scss">
.accordion__content {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &-row {
    width: 100%;
    display: flex;

    &-title {
      width: 30%;
    }
    &-input {
      padding-top: 0;
      width: 70%;
    }
    &-textarea {
      padding-top: 0;
      width: 70%;
    }
  }
}
.mb-10 {
  margin-bottom: 10px;
}
.users {
  margin: 25px 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user {
  &-gray {
    font-size: 13px;
    font-weight: 500;
    color: #8a8a8a;
  }

  &-name {
    font-size: 14px;
    font-weight: 600;
    color: initial;
  }

  &-button {
    font-size: 13px;
    font-weight: 500;
    color: #db4e4e;
    cursor: pointer;
  }
}

.pdf-item {
  padding: 5px;
  box-shadow: 0px 4px 4px 0px #00000012;
  border-radius: 14px;
}
</style>

<template>
  <!-- <form class="form mt-24" @submit.prevent="logIn">
    <div class="d-flex align-items-baseline">
      <span class="phone-icon">
        <i class="fas fa-at"></i>
      </span>
      <div class="input wd-100">
        <input
          v-model.trim="form.phone"
          type="tel"
          placeholder="Email"
          class="input__field input__field_phone input__field_auth"
        />
      </div>
    </div>

    <div class="d-flex align-items-baseline">
      <span class="password-icon">
        <i class="fas fa-unlock-alt"></i>
      </span>
      <div class="input wd-100">
        <input
          v-model.trim="form.password"
          type="password"
          placeholder="Пароль"
          class="input__field input__field_password input__field_auth mt-30"
          readonly
          onfocus="this.removeAttribute('readonly')"
        />
      </div>
    </div>
    <div class="mt-40 d-flex justify-content-center align-items-center">
      <div class="text-center">
        <base-button text="Войти" color="green" size="small" />
      </div>
    </div>
  </form> -->

  <!-- ORY AUTH -->
  <!-- {{ session }} -->
  <div class="w-300">
    <div v-if="isLoading" class="mt-20 text-center loader">
      <img
        :src="THEME === 'domion' ? loaderDomion : loaderGreen"
        alt="loading"
      />
    </div>
    <div v-if="isError && !isLoading">
      <div class="error-title">Ошибка: попробуйте перезагрузить страницу</div>
      <base-button
        @click="removeErrorAndTryAgain"
        text="Перезагрузить"
        color="green"
        size="small"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useMutation } from '@vue/apollo-composable';
import { logErrorMessages } from '@vue/apollo-util';

import { BaseButton } from '@/components/atoms';
import { ory } from '@/config/ory';
import { logIn as Login, oryLogIn } from '@/graphql/Login.graphql';
import { FrontendApi, Configuration } from '@ory/client';
import { EventBus } from '@/utils/eventBus';
import { deleteAllCookies } from '@/utils/cookie';
// import { getCookie } from '@/utils/cookie';

const THEME = config.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');

const ownUrl = ref('');
const session = ref(null);
const logoutUrl = ref(null);
const isLoading = ref(false);
const isError = ref(false);
const form = reactive({
  phone: '',
  password: '',
});

const router = useRouter();
const store = useStore();

const { mutate: oryLogInMutate, onError: errorOryLogIn } = useMutation(
  oryLogIn,
  () => ({
    variables: { data: {} },
  })
);

const { mutate: logIn, onError } = useMutation(Login, () => ({
  variables: {
    data: {
      email: form.phone,
      password: form.password,
    },
  },
  update: async (cache, { data }) => {
    await store.dispatch('app/setAuth', {
      token: data.logIn.token,
      role: data.logIn.role,
      user: '{}',
    });
    await router.push('/app');
    // FIX: this remove after make ory login
    window.location.reload();
  },
}));

onError((error) => {
  store.dispatch('notification/showNotification', {
    text: 'Ошибка авторизации. Логин или пароль неправильные.',
    type: 'error',
  });
  logErrorMessages(error);
});

const oryLoad = async () => {
  try {
    ownUrl.value = window.location.href;

    isLoading.value = true;
    const { data } = await ory.toSession();
    session.value = data;
    await store.dispatch('app/setSession', data);
    EventBus.emit('set-graphql-link');

    isLoading.value = false;
  } catch (error) {
    window.location.replace(
      // `https://ui.kratos.domion.tech/login?return_to=${ownUrl.value}`
      `${config.VUE_APP_KRATOS_UI}/login?return_to=${ownUrl.value}`
    );
  }
};

const oryCreateLogoutLink = async () => {
  const { data: logoutData } = await ory.createBrowserLogoutFlow();
  logoutUrl.value = logoutData.logout_url;
  await store.dispatch('app/setLogoutUrl', logoutData.logout_url);
};

const oryLogin = async () => {
  const { data } = await oryLogInMutate();
  await store.dispatch('app/setAuth', {
    token: data.oryLogIn.token,
    role: data.oryLogIn.role,
    user: '{}',
  });
};

errorOryLogIn((error) => {
  const err = localStorage.getItem('error');
  if (error && !err) window.location.reload();
  localStorage.setItem('error', error);
});

const removeErrorAndTryAgain = () => {
  const error = !!localStorage.getItem('error');
  if (error) {
    localStorage.removeItem('error');
    isError.value = false;
    window.location.reload();
    deleteAllCookies();
  }
};

// ORY
onMounted(async () => {
  isError.value = !!localStorage.getItem('error');
  await oryLoad();
  if (!session.value) return;
  await oryCreateLogoutLink();
  await oryLogin();
  await router.push('/app');

  window.location.reload();
});
</script>

<style scoped lang="scss">
.w-300 {
  width: 300px;
}

.error-title {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.auth-btn {
  padding: 10px 20px;
  background: var(--primary-color);
  color: #fff;
  border-radius: 8px;
  text-decoration: none;
  text-transform: uppercase;
}

.form {
  width: 300px;
  margin: 0 auto;
}

.forgot-password {
  display: inline-block;

  font-size: 12px;
  line-height: 14px;
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
}

.phone-icon,
.password-icon {
  margin-right: 10px;
  color: var(--primary-color);
}
</style>

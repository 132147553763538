<template>
  <base-popup
    class="modal"
    size="large"
    :is-visible="isModalVisible"
    :has-close-icon="true"
    :title="
      manager ? `Редактировать ${employeeType}` : `Добавить ${employeeType}`
    "
    @close="onModalClose"
  >
    <form class="form" @submit.prevent="onFormSubmit">
      <div class="d-flex gap-3 mb-3">
        <div class="w-100">
          <base-input
            label="Имя*"
            placeholder="Имя"
            :value="currentManager.user.firstName"
            @input="onManagerUserInput($event, 'firstName')"
          />
        </div>
        <div class="w-100">
          <base-input
            label="Фамилия*"
            placeholder="Фамилия"
            :value="currentManager.user.lastName"
            @input="onManagerUserInput($event, 'lastName')"
          />
        </div>
      </div>
      <div class="d-flex gap-3 mb-3">
        <div class="w-100">
          <!-- Возможность менять статус сотрудника (пока не используем) -->
          <!-- <base-select
            v-if="manager"
            label="Статус"
            :value="userStatus"
            :options="[
              {
                id: 1,
                name: 'Активный',
                value: true,
              },
              {
                id: 2,
                name: 'Отключен',
                value: false,
              },
            ]"
            @change="onSelectManagerStatus"
            placeholder="Статус"
          /> -->
          <!-- v-else -->
          <base-input
            label="Email*"
            placeholder="Email"
            :value="currentManager.user.email"
            :disabled="!!manager"
            @input="onManagerUserInput($event, 'email')"
          />
        </div>
        <div class="w-100">
          <base-input
            v-model="userPhone"
            label="Телефон*"
            placeholder="Телефон"
            :value="currentManager.user.phone"
            maxlength="16"
            @input="onManagerUserInput($event, 'phone')"
          />
        </div>
      </div>
      <div class="d-flex gap-3 mb-3">
        <div class="w-100">
          <div class="block__item">
            <div class="select-label">Дом(а)*</div>
            <v-select
              ref="select"
              v-model="buildingIds"
              :options="buildingOptions"
              placeholder="Выберите один или больше домов"
              class="modal-select"
              :reduce="(value) => value.id"
              :multiple="true"
              label="value"
            >
              <template #list-header>
                <li
                  class="select-header vs__dropdown-option"
                  @click="selectAll"
                >
                  Все
                </li>
              </template>
              <template v-slot:no-options>
                <div>Извините, ничего не найдено</div>
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div v-for="item in errorList" :key="item.key" class="text-red">
        {{ item.message }}
      </div>
      <!-- Возможность выбрать получать Смс или нет (пока не используем) -->
      <!-- <div>
        <BaseCheckbox
          label="Получать СМС"
          value="1"
          v-model:values="sendSMSCheckboxActivity"
          taggable
          size="medium"
        />
      </div> -->
      <div class="form__field text-right mt-24">
        <base-button
          text="Отмена"
          color="gray"
          class="mr-3"
          @click.prevent="onModalClose"
        />
        <base-button
          text="Сохранить"
          color="green"
          :disabled="isFormDataInvalid || errorList.length !== 0"
          @click.prevent="onFormSubmit"
        />
      </div>
    </form>
  </base-popup>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useMutation, useQuery } from '@vue/apollo-composable';
import {
  BaseInput,
  BaseButton,
  BaseSelect,
  BasePopup,
  BaseCheckbox,
} from '@/components/atoms';
import { getBuildings } from '@/graphql/Votes.graphql';
import {
  updateManager,
  registerManager,
  updateManagerStatus,
} from '@/graphql/Managment.graphql';
import { maskPhone } from '@/utils/phoneMask';
import { useStore } from 'vuex';

const props = defineProps({
  manager: {
    type: Object,
    default: () => ({
      active: false,
      id: null,
      user: {
        id: null,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      },
    }),
  },
  isModalVisible: {
    type: Boolean,
    default: false,
  },
  employeeType: String,
});

const emit = defineEmits(['update', 'create', 'close']);

const emptyManager = {
  id: null,
  active: true,
  receiveSMS: false,
  user: {
    id: null,
    phone: '',
    firstName: '',
    lastName: '',
    email: '',
  },
  buildingIds: '',
};

const errorList = ref([]);
const store = useStore();
const userPhone = ref('');
const select = ref(null);
const buildingIds = ref();
const currentManager = ref({ ...emptyManager });
const buildingOptions = ref([]);
const buildingsIdsData = ref([]);

const rules = computed(() => ({
  user: {
    phone: { required },
    firstName: { required },
    lastName: { required },
    email: { required },
  },
  buildingIds: { required },
}));

const v$ = useVuelidate(rules, currentManager);

const isFormDataInvalid = computed(() => v$.value.$invalid);

const { result: allBuildings } = useQuery(getBuildings);
const { mutate: updateEmployeeUserMutation, onError: onUpdateUserError } =
  useMutation(updateManager);
onUpdateUserError((error) => {
  const errorMessage = String(error);
  const cleanedError =
    errorMessage.match(/ApolloError:\s*(.*?)(\s*at|$)/)?.[1] || '';

  store.dispatch('notification/showNotification', {
    text: cleanedError,
    type: 'error',
  });
});
const { mutate: updateManagerStatusMutation, onError: onUpdateError } =
  useMutation(updateManagerStatus);
onUpdateError((error) => {
  const errorMessage = String(error);
  const cleanedError =
    errorMessage.match(/ApolloError:\s*(.*?)(\s*at|$)/)?.[1] || '';

  store.dispatch('notification/showNotification', {
    text: cleanedError,
    type: 'error',
  });
});
const { mutate: registerManagerMutation, onError: onCreateError } =
  useMutation(registerManager);
onCreateError((error) => {
  const errorMessage = String(error);
  const cleanedError =
    errorMessage.match(/ApolloError:\s*(.*?)(\s*at|$)/)?.[1] || '';

  store.dispatch('notification/showNotification', {
    text: cleanedError,
    type: 'error',
  });
});
const sendSMSCheckboxActivity = ref([]);
const isSendSMSCheckboxActive = ref(false);

watch(sendSMSCheckboxActivity, (newValue) => {
  isSendSMSCheckboxActive.value = newValue.length > 0;
  currentManager.value.receiveSMS = isSendSMSCheckboxActive.value;
});

watch(allBuildings, (value) => {
  if (value) {
    buildingOptions.value = value.getBuildings.map(({ id, name }) => {
      return {
        id,
        value: name,
      };
    });
  }
});

const selectAll = () => {
  select.value.searchEl.blur();
  buildingsIdsData.value = buildingOptions.value.map(({ id }) => id);
  buildingIds.value = buildingOptions.value;
};

const isKeyExist = (argKey) => {
  return errorList.value.find(({ key }) => key === argKey);
};

const removeFromList = (argKey) => {
  errorList.value = errorList.value.filter(({ key }) => key !== argKey);
};

const onManagerUserInput = (event, type) => {
  const value = event.target.value;

  if (type === 'firstName' && !/^[a-zA-Zа-яА-Я\s]*$/.test(value)) {
    if (!isKeyExist('firstName')) {
      errorList.value.push({
        key: 'firstName',
        message: 'Поле Имя содержит недопустимые символы',
      });
    }
  } else if (type === 'firstName') {
    removeFromList('firstName');
    currentManager.value.user[type] = value;
  }

  if (type === 'lastName' && !/^[a-zA-Zа-яА-Я\s]*$/.test(value)) {
    if (!isKeyExist('lastName')) {
      errorList.value.push({
        key: 'lastName',
        message: 'Поле Фамилия содержит недопустимые символы',
      });
    }
  } else if (type === 'lastName') {
    removeFromList('lastName');
    currentManager.value.user[type] = value;
  }

  if (type === 'email' && !/^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
    if (!isKeyExist('email')) {
      errorList.value.push({
        key: 'email',
        message: 'Некорректный адрес электронной почты',
      });
    }
  } else if (type === 'email') {
    removeFromList('email');
    currentManager.value.user[type] = value;
  }

  if (type === 'phone') {
    userPhone.value = maskPhone(value);
    currentManager.value.user[type] = userPhone.value;

    if (!/^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/.test(value)) {
      if (!isKeyExist('phone')) {
        errorList.value.push({
          key: 'phone',
          message: 'Телефон должен быть в формате +7(000)000-00-00',
        });
      }
    } else {
      removeFromList('phone');
    }
  }
};

watch(buildingIds, (value) => {
  if (value) {
    currentManager.value.buildingIds = value;
  }
});

const onSelectBuildingIds = (event, type) => {
  console.log(event, 'event');

  currentManager.value[type] = event.target.value;
};

const onSelectManagerStatus = (opt) => {
  currentManager.value.active = opt.value;
};

const onManagerUpdate = async () => {
  const {
    user: { id: userId, firstName, lastName, phone },
    receiveSMS,
    active,
    id,
  } = currentManager.value;

  await updateEmployeeUserMutation({
    data: {
      firstName,
      lastName,
      phone,
      buildingIds: buildingIds.value,
    },
    userId,
  });

  await updateManagerStatusMutation({
    data: {
      active,
      receiveSMS,
    },
    id,
  });

  emit('update', currentManager.value);
};

const onManagerCreate = async () => {
  const {
    user: { firstName, lastName, email, phone },
    receiveSMS,
  } = currentManager.value;

  const { data } = await registerManagerMutation({
    data: {
      user: {
        firstName,
        lastName,
        email,
        phone,
      },
      receiveSMS,
      role: props.employeeType === 'менеджера' ? 'MANAGER' : 'ADMINUK',
      buildingIds: buildingsIdsData.value.length
        ? buildingsIdsData.value
        : buildingIds.value,
    },
  });

  emit('create', data.registerEmployee);
};

const onFormSubmit = async () => {
  if (isFormDataInvalid.value) return;

  if (props.manager && props.manager.id) {
    await onManagerUpdate();
  } else {
    await onManagerCreate();
  }

  onModalClose();
};

watch(
  () => props.manager,
  (data) => {
    if (data) {
      currentManager.value = JSON.parse(JSON.stringify(data));
      buildingIds.value = JSON.parse(currentManager.value.buildingIds);
    } else {
      currentManager.value = { ...emptyManager };
    }

    currentManager.value.receiveSMS
      ? (sendSMSCheckboxActivity.value = ['1'])
      : (sendSMSCheckboxActivity.value = []);
  },
  { immediate: true }
);

const onModalClose = () => {
  currentManager.value = { ...emptyManager };
  emit('close');
  currentManager.value.user.email = '';
  currentManager.value.user.firstName = '';
  currentManager.value.user.lastName = '';
  currentManager.value.user.phone = '';
  currentManager.value.buildingIds = '';
  buildingIds.value = [];
  errorList.value = [];
};

const userStatus = computed(() => {
  return currentManager.value.active ? 'Активный' : 'Отключен';
});
</script>

<style lang="scss" scoped>
.gap-3 {
  gap: 1rem;
}
.select-label {
  margin-bottom: 7px;
  color: var(--grey-text-color);
}
.text-red {
  margin-bottom: 7px;
  color: var(--button-error-hover-color);
}
</style>

<template>
  <div>
    <div v-if="!isSettingsEmpty" class="d-flex justify-content-between mt-20">
      <div class="d-flex">
        <div class="search-input-wrapper">
          <input
            type="text"
            class="search-input"
            v-model="debtorFilters.search"
          />
          <mdicon size="20" name="magnify" class="search-input-icon" />
        </div>
        <button
          v-if="!filters.archived"
          class="filter__button filter__button_active"
          @click="onShowFilters"
        >
          <mdicon name="filter" class="filter__button-icon" />
          <span class="filter__button-text">Фильтры</span>
          <mdicon
            v-if="!isFiltersEmpty"
            @click.stop.prevent="clearFilters"
            name="close"
            class="filter__button-icon filter__button-icon_close"
          />
        </button>
      </div>

      <div class="d-flex">
        <BaseDropdown
          v-if="chosedUsers.length > 0"
          color="green"
          text="Выбрать действие"
          size="extra-small"
          class="mr-10"
          :options="optionsBtn"
          @choose="onChooseOption"
        />
        <base-button
          v-if="!filters.archived"
          color="green"
          size="extra-small"
          :icon="{
            name: 'cog',
            color: 'white',
          }"
          @click="onClick"
        ></base-button>
      </div>
    </div>
    <div v-if="!isSettingsEmpty" class="mt-20">
      <base-tabs-wrapper
        v-if="!filters.archived"
        @update:selectedTitle="handleTabClick"
      >
        <base-tab title="Все">
          <DebtorsList
            v-show="!isLoadingCheck"
            :debtors="debtors"
            :pagination="pagination"
            :allFilters="allFilters"
            :chosedUsers="chosedUsers"
            :selectedDebtor="selectedDebtor"
            filter="all"
            @onRowClick="onRowClick"
            @onChangePage="onChangePage"
            @chosedDebtors="onChosedDebtors"
            @chosedAll="onChosedAll"
            @uncheckAllUsers="emit('uncheckAllUsers')"
            @uncheckId="uncheckById"
          />
          <div v-if="isLoadingCheck" class="mt-20 text-center">
            <img
              :src="THEME === 'domion' ? loaderDomion : loaderGreen"
              alt="loading"
            />
          </div>
        </base-tab>
        <base-tab title="Уведомлены">
          <DebtorsList
            :debtors="debtors"
            :pagination="pagination"
            filter="notify"
            @onRowClick="onRowClick"
            @onChangePage="onChangePage"
          />
        </base-tab>
        <base-tab title="Направлено в суд">
          <DebtorsList
            :debtors="debtors"
            :pagination="pagination"
            filter="subpoenaed"
            @onRowClick="onRowClick"
            @onChangePage="onChangePage"
          />
        </base-tab>
      </base-tabs-wrapper>
      <div v-else>
        <DebtorsList
          :debtors="debtors"
          :pagination="pagination"
          filter="archived"
          @onRowClick="onRowClick"
          @onChangePage="onChangePage"
        />
      </div>
    </div>

    <div v-if="isSettingsEmpty" class="d-flex debtors-info__settings-btn mt-20">
      <base-button
        color="green"
        text="Настроить страницу"
        size="extra-small"
        :icon="{
          name: 'cog',
          color: 'white',
        }"
        @click="onClick"
      ></base-button>
    </div>
  </div>

  <DebtorsModalInfo
    :is-modal-visible="isModalVisible"
    :debtor="selectedDebtor"
    @close="setPopupVisibility(false)"
    @chooseOption="onChooseOption"
  />
  <DebtPdfModal
    :is-modal-visible="isDebtPdfNotificationVisible"
    :users="chosedUsers"
    @submit="onNotificationSubmit"
    @close="setPdfModalVisibility(false)"
  />
  <DebthNotification
    :is-modal-visible="isDebthNotificationVisible"
    :debtorsSettings="debtorsSettings"
    :users="chosedUsers"
    @submit="onNotificationSubmit"
    @close="setNotificationVisibility(false)"
  />
</template>
<script setup>
import { reactive, defineEmits, ref, computed, watch, onMounted } from 'vue';
import { BaseTabsWrapper, BaseTab, BaseButton } from '@/components/atoms/';
import DebtorsList from './DebtorsList.vue';
import BaseDropdown from '../atoms/components/BaseDropdown.vue';
import DebtorsModalInfo from './DebtorsModalInfo.vue';
import DebthNotification from './DebthNotification.vue';
import DebtPdfModal from './DebtPdfModal.vue';
import { useMutation } from '@vue/apollo-composable';
import {
  setWarningDelay,
  sendDebtorsWarnings,
} from '@/graphql/Debtors.graphql';
import { useStore } from 'vuex';

const store = useStore();
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const THEME = config.VUE_APP_THEME;

const emit = defineEmits([
  'click',
  'showFilters',
  'onChangePage',
  'filter',
  'clearFilters',
  'chosedAll',
  'uncheckAllUsers',
  'searching',
]);

const props = defineProps({
  debtors: Array,
  pagination: Object,
  isSettingsEmpty: Boolean,
  filters: Object,
  debtorsSettings: Object,
  allFilters: Object,
  chosedDebtors: Array,
  isLoadingCheck: Boolean,
});

const debtorFilters = reactive({
  search: null,
});

watch(
  debtorFilters,
  (value) => {
    if (value) {
      emit('searching', value.search);
    }
  },
  { deep: true }
);

const dropDownParam = ref([]);

const optionsBtn = ref([
  { id: 'notif', name: 'Сформировать уведомление' },
  { id: 'pdf', name: 'Сформировать PDF уведомление' },
  // { id: 'law', name: 'Подать документы в суд' },
  {
    id: 'time',
    name: 'Дать отсрочку',
    dropdown: dropDownParam.value,
  },
]);

const isModalVisible = ref(false);
const isDebthNotificationVisible = ref(false);
const selectedDebtor = ref(null);
const chosedUsers = ref([]);
const isDebtPdfNotificationVisible = ref(false);
const removedUsers = ref([]);

const onChosedAll = () => {
  emit('chosedAll');
};

const isFiltersEmpty = computed(() => {
  if (!props.filters) return true;

  const filterKeys = Object.keys(props.filters).filter(
    (key) =>
      key !== 'notified' &&
      key !== 'subpoenaed' &&
      key !== 'archived' &&
      key !== 'q'
  );

  if (filterKeys.length === 0) return true;

  const values = filterKeys.map((key) => props.filters[key]);

  return values.every((value) => value === 0);
});

const setPopupVisibility = async (status) => {
  isModalVisible.value = status;

  if (!status) {
    chosedUsers.value = chosedUsers.value.filter(
      (user) => user.id !== selectedDebtor.value.id
    );
  }
};

const setNotificationVisibility = async (status) => {
  isDebthNotificationVisible.value = status;
};

const setPdfModalVisibility = async (status) => {
  isDebtPdfNotificationVisible.value = status;
};

const onChosedDebtors = (debtors) => {
  chosedUsers.value = [...new Set([...chosedUsers.value, ...debtors])];
};

const uncheckById = (id) => {
  removedUsers.value.push(id);
  chosedUsers.value = chosedUsers.value.filter((item) => {
    if (item.user && item.id) {
      return item.id !== id;
    } else {
      return item.id !== id;
    }
  });
};

const handleTabClick = (title) => {
  let str;
  switch (title) {
    case 'Все':
      str = 'all';
      break;
    case 'Уведомлены':
      str = 'notified';
      break;
    case 'Направлено в суд':
      str = 'subpoenaed';
      break;
    default:
      str = 'all';
  }
  emit('filter', str);
};

const onRowClick = async (item) => {
  selectedDebtor.value = item;
  chosedUsers.value = [item];

  setPopupVisibility(true);
};

const { mutate: sendWarningsMutate } = useMutation(sendDebtorsWarnings);

const onNotificationSubmit = async (notificationData) => {
  const { data } = await sendWarningsMutate(notificationData);
  if (data) {
    setNotificationVisibility(false);
    if (isDebtPdfNotificationVisible.value === true) {
      setPdfModalVisibility(false);
    }
    store.dispatch('notification/showNotification', {
      type: 'success',
      text: 'Отправлено',
    });
  }
};

const { mutate: setWarningMutate } = useMutation(setWarningDelay);

const giveDebtorDelay = async (days) => {
  let debtorsIds = chosedUsers.value.map((user) => user.id);
  let debtorsDelayData = { debtorsIds, days };

  const { data } = await setWarningMutate(debtorsDelayData);
  if (data) {
    store.dispatch('notification/showNotification', {
      type: 'success',
      text: 'Успешно',
    });
  }
};

const onChooseOption = (item) => {
  switch (item.id) {
    case 'pdf':
      setPdfModalVisibility(true);
      break;
    case 'notif':
      setNotificationVisibility(true);
      break;
    case 'subTime':
      giveDebtorDelay(item.dayCount);
      break;
    default:
      break;
  }
};

const onChangePage = (data) => {
  emit('onChangePage', data);
};

const onShowFilters = () => {
  emit('showFilters');
};

const clearFilters = () => {
  emit('clearFilters');
};

const onClick = () => {
  emit('click', true);
};

watch(
  () => props.chosedDebtors,
  (value) => {
    chosedUsers.value = value;
  },
  { deep: true }
);

onMounted(() => {
  for (let i = 1; i <= 50; i++) {
    dropDownParam.value.push({ id: 'subTime', dayCount: i, name: `${i} д.` });
  }
});
</script>

<style scoped lang="scss">
@import '~@/assets/styles/_colors';

.debtors-info__settings-btn {
  width: 100%;
  justify-content: center;
}
.filter-input {
  font-family: 'Golos' !important;
  font-size: 18px !important;
  width: 100%;
  margin-right: 20px;
  border: 1px solid map-get($lightgray-stroke, 'normal');
  border-radius: 4px;
  padding: 0 0 0 10px;
}
.filter-input::placeholder {
  color: #535352;
}
.filter__item {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  margin-right: 40px;
}
.filter-select:not(:last-child) {
  margin-right: 20px;
}
.filter__item_active .filter__item-text_title {
  font-weight: 700 !important;
  color: map-get($green, 'normal') !important;
}
.filter__item_active .filter__item-text_number {
  color: map-get($lightgray-text, 'normal');
}
.filter__item-text {
  font-size: 18px;
  margin-right: 5px;
}
.filter__item-text_title {
  color: map-get($lightgray-text, 'normal');
  font-weight: 700;
  transition: all 0.3s ease 0s;
}
.filter__item-text_number {
  color: map-get($lightgray-text, 'normal');
  font-weight: 500;
  transition: all 0.3s ease 0s;
}
// .filter {
//   max-width: 778px;
// }
.search-input-wrapper {
  position: relative;
  width: 300px;
  height: 34px;
  margin-right: 20px;
}
.search-input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: #f4f4f4;
  border: none;
  padding: 0 20px 0 40px;
  font-family: 'Golos';
  font-size: 16px;
}
.search-input-icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 10px;
  color: #535352;
}
.filter__button {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease 0s;
}
.filter__button_disable {
  background: map-get($lightgray-text, 'normal');
}
.filter__button_disable:hover {
  background: map-get($lightgray-text, 'hover');
}
.filter__button-text {
  font-family: 'Golos';
  font-weight: 600;
  font-size: 15px;
  margin: 0 20px 0 5px;
}
.filter__button_active {
  background: map-get($green, 'normal');
}
.filter__button_active:hover {
  background: map-get($green, 'hover');
}

// .mt-20 {
//     margin-top: 20px;
// }
.mr-20 {
  margin-right: 20px;
}
</style>

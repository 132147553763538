<template>
  <div class="tab-container">
    <form @submit.prevent="onFormSubmit">
      <div class="new-news">
        <div class="new-news__title">
          <h2>Новая новость</h2>
        </div>
        <span class="select-label">Комплекс*</span>
        <v-select
          class="news__modal-select modal-select"
          :options="complexOptions"
          v-model="currentNews.buildingIds"
          :multiple="true"
          placeholder="\"
          :reduce="(value) => +value.id"
          label="value"
        >
          <template v-slot:no-options>
            <div>Извините, ничего не найдено</div>
          </template></v-select
        >
        <div class="new-news__inputs">
          <div class="new-news__input-item">
            <label for="theme" class="new-news__input__label">Тема*</label>
            <input
              type="text"
              id="theme"
              placeholder="\"
              class="new-news__input"
              v-model.trim="currentNews.title"
            />
          </div>
          <div class="new-news__input-item">
            <label for="content" class="new-news__input__label"
              >Содержимое*</label
            >
            <textarea
              type="text"
              id="content"
              placeholder="\"
              class="new-news__input new-news__textarea"
              v-model.trim="currentNews.text"
            />
          </div>
        </div>
        <div class="submit-button">
          <base-button
            color="green"
            text="Отправить"
            size="extra-m"
            :disabled="isFormDataInvalid"
          ></base-button>
        </div>
      </div>
    </form>
  </div>
  <BaseGlobalLoader v-if="isGlobalLoading" />
</template>
<script setup>
import { ref, defineEmits, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { BaseButton, BaseGlobalLoader } from '@/components/atoms';
import { createNews, getAllComplexes } from '@/graphql/News.graphql';
import { useMutation, useQuery } from '@vue/apollo-composable';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const emit = defineEmits(['create']);
const store = useStore();
const currentNews = ref({
  id: null,
  title: '',
  text: '',
  buildingIds: [],
});

const rules = computed(() => {
  return {
    title: { required },
    text: { required },
    buildingIds: { required },
  };
});

const v$ = useVuelidate(rules, currentNews);

const isFormDataInvalid = computed(() => {
  return v$.value.$invalid;
});

const complexOptions = ref([]);

const { result: allComplexes } = useQuery(getAllComplexes);

watch(allComplexes, (value) => {
  complexOptions.value = value.getAllComplexes.map(({ id, name }) => {
    return {
      id,
      value: name,
    };
  });
});

const isGlobalLoading = ref(false);

const { mutate: createNewsMutation } = useMutation(createNews);

const onNewsCreate = async () => {
  const { title, text, buildingIds } = currentNews.value;
  await createNewsMutation({
    data: {
      title,
      text,
    },
    buildingIds,
  });
  emit('create');
};

const onFormSubmit = async () => {
  // console.log(currentNews.value);
  const isFormCanBeSubmited = confirm('Опубликовать новость?');
  if (isFormCanBeSubmited) {
    isGlobalLoading.value = true;
    await onNewsCreate();
    (currentNews.value.title = ''),
      (currentNews.value.text = ''),
      (currentNews.value.complexIds = []);
    store.dispatch('notification/showNotification', {
      text: 'Новость успешно создана!',
      type: 'success',
    });
    isGlobalLoading.value = false;
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_colors';
.tab-container {
  border-top: 1px solid var(--grey-opacity);
  margin-top: 37px;
}

.new-news {
  &__title {
    padding: 16px 0;
  }

  &__title h2 {
    color: var(--black);
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
  }

  &__inputs {
    margin-top: 25px;
  }

  &__input-item {
    display: flex;
    flex-direction: column;
  }

  &__input-item:not(:first-child) {
    margin-top: 20px;
  }

  &__input {
    color: map-get($primary-text, 'normal');
    font-size: 16px;
    line-height: 22px;
    padding: 10px 0 10px 12px;
    border: 1px solid var(--grey-border-color);
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
    margin-top: 5px;
    width: 70%;
    border-radius: 10px;
  }

  &__textarea {
    resize: vertical;
    min-height: 60px;
  }
}

.submit-button {
  margin-top: 200px;
  display: flex;
  justify-content: flex-end;
  justify-self: flex-end;
}
.news__modal-select {
  width: 320px;
}
.select-label {
  margin-bottom: 5px;
  display: block;
}
.new-news__textarea {
  font-family: 'Golos';
}
</style>

<template>
  <div class="mt-20">
    <base-table v-if="debtors.length > 0" class="history-table">
      <template v-slot:head>
        <base-table-row>
          <base-table-head-cell v-if="filter !== 'archived'" width="9%">
            <base-checkbox
              :value="0"
              v-model:values="allChecked"
              class="checkbox_big"
            ></base-checkbox>
          </base-table-head-cell>
          <base-table-head-cell width="10%">Пользователь</base-table-head-cell>
          <base-table-head-cell width="15%">ЖК</base-table-head-cell>
          <base-table-head-cell width="10%">Лицевой счет</base-table-head-cell>
          <base-table-head-cell width="10%">Адрес</base-table-head-cell>
          <base-table-head-cell width="15%">Задолженность</base-table-head-cell>
          <base-table-head-cell width="15%"
            >Период задолженности</base-table-head-cell
          >
          <base-table-head-cell width="24%"
            >Предупреждения</base-table-head-cell
          >
        </base-table-row>
      </template>
      <template v-slot:body>
        <base-table-row v-for="data in debtors" :key="data.id">
          <base-table-cell v-if="filter !== 'archived'" width="9%">
            <base-checkbox
              :value="data"
              v-model:values="checkedDebtor"
              @click="onChooseDebtor(data.id)"
              class="checkbox_big"
            ></base-checkbox>
          </base-table-cell>
          <base-table-cell width="10%" @click="onRowClick(data)">
            <span class="cell-text">
              {{ data.user.firstName }}
              {{ data.user.lastName }}
            </span>
          </base-table-cell>
          <base-table-cell width="15%" @click="onRowClick(data)">
            <span class="cell-text">
              {{ data.complexName }}
            </span>
          </base-table-cell>
          <base-table-cell width="10%" @click="onRowClick(data)">
            <span class="cell-text">
              {{ data.account }}
            </span>
          </base-table-cell>
          <base-table-cell width="10%" @click="onRowClick(data)">
            <span class="cell-text">
              {{ data.address }}
            </span>
          </base-table-cell>
          <base-table-cell width="15%" @click="onRowClick(data)">
            <span class="cell-text--price"> {{ data.amount }}₽ </span>
          </base-table-cell>
          <base-table-cell width="15%" @click="onRowClick(data)">
            <span class="cell-text"> {{ data.period }} дней </span>
          </base-table-cell>
          <base-table-cell width="24%">
            <span class="cell-text" @click="onRowClick(data)">
              {{ data.warnsCount }}
            </span>
          </base-table-cell>
        </base-table-row>
      </template>
    </base-table>
    <div v-else class="cards-client__empty">
      <div class="cards-client__empty-text">Здесь пока пусто</div>
      <img :src="emptyImg" class="cards-client__empty-img" alt="empty-claims" />
    </div>
    <the-pagination
      v-if="debtors.length > 0"
      :totalPages="pagination?.totalPages"
      :currentPage="pagination?.currentPage"
      @pageChanged="onChangePage"
      class="pagination"
    ></the-pagination>
  </div>
</template>
<script setup>
import {
  defineProps,
  defineEmits,
  ref,
  onMounted,
  watch,
  onUnmounted,
} from 'vue';
import ThePagination from '@/components/ThePagination.vue';
import {
  BaseTable,
  BaseTableCell,
  BaseTableHeadCell,
  BaseTableRow,
} from '@/components/BaseTable';
import { BaseCheckbox } from '@/components/atoms';
import { EventBus } from '../../utils/eventBus';
import emptyDomion from '@/assets/images/empty-claims.png';
import emptyGreen from '@/assets/images/claims-green/empty.png';

const THEME = config.VUE_APP_THEME;
const emit = defineEmits([
  'onRowClick',
  'onChangePage',
  'chosedDebtors',
  'chosedAll',
  'uncheckAllUsers',
  'uncheckId',
]);

const props = defineProps({
  filter: { type: String, required: true },
  debtors: { type: Array },
  pagination: { type: Object },
  allFilters: Object,
  chosedUsers: Array,
  selectedDebtor: Object,
});

const emptyImg = THEME === 'domion' ? emptyDomion : emptyGreen;
const allChecked = ref([]);
const checkedDebtor = ref([]);
const uncheckedUsers = ref([]);
const choosedUsersList = ref([]);

const onRowClick = (row) => {
  emit('onRowClick', row);
};

const onChangePage = (data) => {
  emit('onChangePage', data);

  setTimeout(() => {
    const matchedDebtors = props.debtors.filter((debtor) =>
      choosedUsersList.value.some((user) => user.id === debtor.id)
    );

    checkedDebtor.value = [
      ...new Set([...matchedDebtors.map((debtor) => debtor)]),
    ].filter((user) => !uncheckedUsers.value.includes(user.id));
  }, 300);
};

const onChooseDebtor = (id) => {
  const isIdInChoosedUsersList = choosedUsersList.value.some(
    (item) => item.id === id
  );

  if (isIdInChoosedUsersList) {
    uncheckedUsers.value.push(id);
    emit('uncheckId', id);
  }
};

const checkAllDebtors = () => {
  checkedDebtor.value = [
    ...new Set([
      ...props.debtors.map((debtor) => debtor.user),
      ...choosedUsersList.value.map((user) => user),
    ]),
  ].filter((user) => !uncheckedUsers.value.includes(user.id));
};

watch(
  checkedDebtor,
  (value) => {
    emit('chosedDebtors', value);
  },
  { deep: true }
);

watch(allChecked, async (value) => {
  if (value[0] === 0) {
    checkedDebtor.value = [
      ...new Set([
        ...props.debtors.map((debtor) => debtor),
        ...choosedUsersList.value.map((user) => user),
      ]),
    ].filter((user) => !uncheckedUsers.value.includes(user.id));

    emit('chosedAll');
  } else {
    checkedDebtor.value = [];
    emit('uncheckAllUsers');
  }
});

watch(
  () => props.chosedUsers,
  (value) => {
    choosedUsersList.value = [...value];
  },
  { deep: true }
);

watch(
  () => props.selectedDebtor,
  (value) => {
    if (value) {
      checkedDebtor.value = [];
    }
  },
  { deep: true }
);

onMounted(() => {
  EventBus.on('check-all-debtors', () => checkAllDebtors());
});

onUnmounted(() => {
  EventBus.off('check-all-debtors', () => checkAllDebtors());
});
</script>

<style scoped lang="scss">
.pagination {
  margin-top: 25px;
}

.message {
  margin-bottom: 7px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.13);
  padding: 7px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.message__icon {
  color: var(--primary-color);
}

.cell-text {
  font-weight: 500;
  font-size: 13px;
  color: #8a8a8a;
}
.cell-text--price {
  font-weight: 500;
  font-size: 14px;
  color: #1c252cc7;
}
</style>

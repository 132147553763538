<template>
  <base-popup
    size="largest"
    topIndent="80"
    :is-visible="isModalVisible"
    title="Сформировать PDF уведомление"
    subtitle=""
    @close="onModalClose"
  >
    <div class="block__item">
      <span class="select-label">Тема письма</span>
      <input
        type="text"
        placeholder="Тема письма"
        class="filter-input"
        v-model="emailData.theme"
      />
    </div>
    <div class="block__item">
      <span class="select-label">Содержание</span>
      <textarea
        type="text"
        placeholder="Содержание письма"
        class="filter-input filter-textarea"
        v-model="emailData.content"
      />
    </div>
    <div class="users">
      <div
        v-for="(user, idx) in users"
        :key="idx"
        class="pdf-item d-flex align-items-center justify-content-between"
      >
        <div class="d-flex align-items-center">
          <div class="mr-10">
            <img src="@/assets/images/pdf.png" alt="pdf-icon" />
          </div>
          <div>
            <span class="user-gray">Пользователь: </span>
            <span class="user-name"
              >{{ user.firstName ? user.firstName : user.user.firstName }}
              {{ user.lastName ? user.lastName : user.user.lastName }}</span
            >
          </div>
        </div>
        <div @click="getPdfByUser(user)" class="d-flex user-button">
          <div>Просмотреть</div>
          <mdicon name="chevron-right" size="16" />
        </div>
      </div>
    </div>

    <div class="line"></div>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <base-button
          text="Распечатать"
          color="transparent"
          textColor="green"
          class="mr-3"
          :icon="{
            name: 'printer-outline',
            color: 'var(--primary-color)',
          }"
          @click.prevent="getAllPdfsAndPrint"
        />
        <base-button
          text="Скачать"
          color="transparent"
          textColor="gray"
          :icon="{
            name: 'download',
            color: '#949494',
          }"
          class="mr-3"
          @click.prevent="getAllPdfsAndSave"
        />
      </div>

      <base-button
        text="Отправить"
        color="green"
        @click.prevent="onFormSubmit"
      />
    </div>
  </base-popup>
</template>
<script setup>
import { defineProps, defineEmits, reactive, watch } from 'vue';
import { BasePopup, BaseButton } from '@/components/atoms';
import { useMutation } from '@vue/apollo-composable';
import {
  getDebtorsPdfWarningsUrls,
  createDebtorsDocs,
  sendDebtorsWarningsByEmail,
} from '@/graphql/Debtors.graphql';
import { useStore } from 'vuex';
import { PDFDocument } from 'pdf-lib';

const store = useStore();

const emit = defineEmits(['close', 'submit']);

const props = defineProps({
  isModalVisible: {
    type: Boolean,
    default: false,
  },
  users: Array,
});

const emailData = reactive({
  theme: '',
  content: '',
});

const { mutate: getDebtorsMutate } = useMutation(getDebtorsPdfWarningsUrls);
const { mutate: createDocsMutate } = useMutation(sendDebtorsWarningsByEmail);

const onModalClose = (isSubmit) => {
  isSubmit !== true ? (isSubmit = false) : (isSubmit = true);
  let isClosed = true;

  if (!isSubmit) {
    isClosed = confirm(
      'Вы действительно хотите закрыть окно? Внесенные изменения не сохранятся'
    );
  }
  if (isClosed) {
    emailData.theme = '';
    emailData.content = '';
    emit('close');
  }
};

const onFormSubmit = async () => {
  if (!emailData.theme.trim() || !emailData.content.trim()) {
    store.dispatch('notification/showNotification', {
      type: 'error',
      text: 'Поля не должны быть пустыми',
    });
    return;
  }

  let debtorsIds = props.users.map((user) => user.id);
  const { theme, content } = emailData;

  const { data } = await createDocsMutate({ debtorsIds });

  emit('submit', { debtorsIds, email: { title: theme, content } });

  if (data.createDebtorsDocs) onModalClose();
  store.dispatch('notification/showNotification', {
    type: 'success',
    text: 'PDF документы были высланы выбранным пользователям',
  });
};

const getPdfByUser = async (user) => {
  const { data } = await getDebtorsMutate({ debtorsIds: [user.id] });
  window.open(data.getDebtorsPdfWarningsUrls[0], '_blank').focus();
};

const getAllPdfs = async () => {
  if (props.users) {
    let debtorsIds = props.users.map((user) => user.id);
    const { data } = await getDebtorsMutate({ debtorsIds });
    return data.getDebtorsPdfWarningsUrls;
  }
  return [];
};

const getAllPdfsAndSave = async () => {
  const allPdfLinks = await getAllPdfs();
  console.log(allPdfLinks);

  for (const link of allPdfLinks) {
    await savePdf(link);
  }
};

const savePdf = async (link) => {
  const response = await fetch(link);
  const blob = await response.blob();

  const fileName = link.split('?')[0].split('/').pop() || 'file.pdf';

  const linkElement = document.createElement('a');
  const objectUrl = URL.createObjectURL(blob);
  linkElement.href = objectUrl;
  linkElement.download = fileName;
  document.body.appendChild(linkElement);
  linkElement.click();
  document.body.removeChild(linkElement);

  URL.revokeObjectURL(objectUrl);

  await new Promise((resolve) => setTimeout(resolve, 100));
};

const getAllPdfsAndPrint = async () => {
  const allPdfLinks = await getAllPdfs();

  const mergedPdf = await PDFDocument.create();

  for (const link of allPdfLinks) {
    await addPdfToMergedDocument(mergedPdf, link);
  }

  await printMergedPdf(mergedPdf);
};

const addPdfToMergedDocument = async (mergedPdf, link) => {
  const response = await fetch(link);
  const pdfBytes = await response.arrayBuffer();

  const pdfToMerge = await PDFDocument.load(pdfBytes);
  const copiedPages = await mergedPdf.copyPages(
    pdfToMerge,
    pdfToMerge.getPageIndices()
  );

  copiedPages.forEach((page) => mergedPdf.addPage(page));
};

const printMergedPdf = async (mergedPdf) => {
  const pdfBytes = await mergedPdf.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });

  const objectUrl = URL.createObjectURL(blob);
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = objectUrl;

  document.body.appendChild(iframe);

  iframe.onload = () => {
    const printWindow = iframe.contentWindow;
    if (printWindow) {
      printWindow.focus();
      printWindow.print();
    }
  };
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/_colors';
.block {
  position: relative;
  width: 100%;
}

.block__item:nth-child(1) {
  margin-right: 10px;
  width: 40%;
}

.block__item:nth-child(2) {
  width: 60%;
}

.second-block {
  width: 100%;
}

.select-label {
  font-family: 'Golos' !important;
  font-size: 14px;
  margin-bottom: 9px;
  display: block;
}

.filter-input {
  font-family: 'Golos' !important;
  margin-bottom: 15px;
  height: 34px;
  font-size: 14px !important;
  width: 100%;
  margin-right: 20px;
  border: 1px solid map-get($lightgray-stroke, 'normal');
  border-radius: 10px;
  padding: 0 0 0 10px;
}
.filter-input::placeholder {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.9;
}

.filter-textarea {
  width: 100% !important;
  padding-top: 5px;
  margin: 0;
  font-family: 'Golos' !important;
  height: 80px;
  resize: none;
}
.users {
  margin: 25px 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user {
  &-gray {
    font-size: 13px;
    font-weight: 500;
    color: #8a8a8a;
  }

  &-name {
    font-size: 14px;
    font-weight: 600;
    color: initial;
  }

  &-button {
    font-size: 13px;
    font-weight: 500;
    color: #db4e4e;
    cursor: pointer;
  }
}

.pdf-item {
  padding: 5px;
  box-shadow: 0px 4px 4px 0px #00000012;
  border-radius: 14px;
}
</style>

<template>
  <base-popup
    size="large"
    topIndent="80"
    :is-visible="isModalVisible"
    title="Информация о пользователе"
    subtitle=""
    @close="onModalClose"
  >
    <div class="d-flex justify-content-between">
      <table class="no-border no-padding">
        <tr>
          <th>Пользователь:</th>
          <td>{{ debtor.user.firstName }} {{ debtor.user.lastName }}</td>
        </tr>
        <tr>
          <th>Жк:</th>
          <td>{{ debtor.complexName }}</td>
        </tr>
        <!-- <tr>
            <th>№ Дома:</th>
            <td>{{ debtor.address }}</td>
        </tr>
        <tr>
            <th>Квартира:</th>
            <td>{{ debtor.account }}</td>
        </tr> -->
        <tr>
          <th>Лицевой счет:</th>
          <td>{{ debtor.account }}</td>
        </tr>
        <tr>
          <th>Номер телефона:</th>
          <td>{{ debtor.user.phone ?? 'Нет номера телефона' }}</td>
        </tr>
        <tr>
          <th class="cell-bold">Задолженность:</th>
          <td>{{ debtor.amount }}₽</td>
        </tr>
        <tr>
          <th>Время задолженности:</th>
          <td>{{ debtor.period }} дней</td>
        </tr>
        <tr>
          <th class="cell-bold">Предупреждения:</th>
          <td>{{ debtor.warnsCount }}</td>
        </tr>
      </table>
      <div>
        <BaseDropdown
          color="green"
          text="Выбрать действие"
          size="extra-small"
          class="mr-10"
          :options="optionsBtn"
          @choose="onChooseOption"
        />
      </div>
    </div>

    <div v-if="actionHistory.length > 0">
      <div class="title mt-20 mb-10">История действий</div>
      <table class="no-border no-padding">
        <tr v-for="(action, idx) in actionHistory" :key="idx">
          <th>{{ new Date(action.date).toLocaleString('ru-RU') }}</th>
          <td>{{ action.text }}</td>
        </tr>
      </table>
    </div>

    <div class="line"></div>
    <div class="d-flex justify-content-end">
      <base-button
        text="Закрыть"
        color="gray"
        class="mr-3"
        @click.prevent="onModalClose(true)"
      />
    </div>
  </base-popup>
</template>
<script setup>
import { defineEmits, defineProps, onMounted, ref, watch } from 'vue';
import { BasePopup, BaseButton } from '@/components/atoms';
import BaseDropdown from '../atoms/components/BaseDropdown.vue';
import { useQuery } from '@vue/apollo-composable';
import { getDebtorsHistory } from '@/graphql/Debtors.graphql';
import { computed } from 'vue';

const emit = defineEmits(['close', 'chooseOption']);

const props = defineProps({
  isModalVisible: {
    type: Boolean,
    default: false,
  },
  debtor: Object,
});

const dropDownParam = ref([]);

const optionsBtn = ref([
  { id: 'notif', name: 'Сформировать уведомление' },
  { id: 'pdf', name: 'Сформировать PDF уведомление' },
  // { id: 'law', name: 'Подать документы в суд' },
  {
    id: 'time',
    name: 'Дать отсрочку',
    dropdown: dropDownParam.value,
  },
]);

const debtorId = ref(null);
const actionHistory = ref([]);

const { result: debtorHistory, refetch: debtorHistoryRefetch } = useQuery(
  getDebtorsHistory,
  () => ({ id: debtorId.value }),
  computed(() => ({
    enabled: debtorId.value !== null ? true : false,
  }))
);

const onChooseOption = (item) => {
  emit('chooseOption', item);
};

const onModalClose = (isSubmit) => {
  isSubmit !== true ? (isSubmit = false) : (isSubmit = true);
  let isClosed = true;
  if (!isSubmit) {
    isClosed = confirm(
      'Вы действительно хотите закрыть окно? Внесенные изменения не сохранятся'
    );
  }
  if (isClosed) {
    emit('close');
  }
};

watch(
  () => props.debtor,
  (value) => {
    if (value) {
      debtorId.value = value.id;
    }
  },
  { deep: true }
);

watch(
  debtorId,
  async (newId) => {
    if (newId) {
      await debtorHistoryRefetch();
    }
  },
  { deep: true }
);

watch(
  debtorHistory,
  (value) => {
    if (value) {
      actionHistory.value = value.getDebtorsHistory;
    }
  },
  { deep: true }
);

onMounted(() => {
  for (let i = 1; i <= 50; i++) {
    dropDownParam.value.push({ id: 'subTime', dayCount: i, name: `${i} д.` });
  }
});
</script>

<style scoped>
.mb-10 {
  margin-bottom: 10px;
}

.line {
  margin: 30px 0;
  width: 100%;
  height: 1px;
  background-color: #00000047;
}

.no-border {
  border-collapse: collapse;
}

.no-border th,
.no-border td {
  text-align: left;
  border: none;
  padding: 10px 15px 10px 0;
}

.no-border th {
  font-size: 13px;
  font-weight: 500;
  color: #8a8a8a;
}

.no-border td {
  font-size: 14px;
  font-weight: 600;
  color: initial;
}

.cell-bold {
  font-weight: 700 !important;
}
</style>

<template>
  <div class="managers">
    <div class="results d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex">
        <div class="results__title">Сотрудники</div>
      </div>
      <div class="d-flex">
        <!-- <div v-if="managers.length" class="d-flex align-items-center mr-4">
          <div
            :class="[
              'results__view-switcher mr-3',
              {
                'results__view-switcher_active': view === 'list',
              },
            ]"
            @click="onSetView('list')"
          >
            <mdicon size="21" name="format-list-bulleted-square" />
          </div>
          <div
            :class="[
              'results__view-switcher',
              {
                'results__view-switcher_active': view === 'card',
              },
            ]"
            @click="onSetView('card')"
          >
            <mdicon name="view-sequential" />
          </div>
        </div> -->
        <base-button
          @click="onManagerModalOpen"
          color="green"
          size="m"
          :text="`Добавить ${
            activeTab === 'Менеджеры' ? 'менеджера' : 'администратора'
          }`"
          :disabled="false"
        />
      </div>
    </div>

    <base-tabs-wrapper @update:selectedTitle="handleTabClick">
      <base-tab title="Менеджеры">
        <div class="mt-3">
          <div v-if="isLoading" class="mt-20 text-center">
            <img
              :src="THEME === 'domion' ? loaderDomion : loaderGreen"
              alt="loading"
            />
          </div>
          <slot v-else>
            <div v-if="managers.length">
              <div v-if="view === 'list'" class="managers__list-view">
                <base-table>
                  <template v-slot:head>
                    <base-table-row>
                      <base-table-head-cell width="25%"
                        >Имя Фамилия</base-table-head-cell
                      >
                      <base-table-head-cell width="24.5%"
                        >Электронная почта</base-table-head-cell
                      >
                      <base-table-head-cell width="15%"
                        >Мобильный телефон</base-table-head-cell
                      >
                      <base-table-head-cell width="15%"
                        >ЖК</base-table-head-cell
                      >
                      <base-table-head-cell width="12.5%"
                        >Статус</base-table-head-cell
                      >
                      <!-- <base-table-head-cell width="15%"
                        >СМС информирование</base-table-head-cell
                      > -->
                      <base-table-head-cell width="8.5%"></base-table-head-cell>
                    </base-table-row>
                  </template>
                  <template v-slot:body>
                    <base-table-row
                      v-for="manager in managers"
                      :key="manager.id"
                    >
                      <base-table-cell width="25%" class="color-black"
                        >{{ manager.user.firstName }}
                        {{ manager.user.lastName }}
                      </base-table-cell>
                      <base-table-cell width="24.5%">{{
                        manager.user.email
                      }}</base-table-cell>
                      <base-table-cell width="15%">{{
                        manager.user.phone || ''
                      }}</base-table-cell>
                      <base-table-cell width="15%">
                        {{
                          manager.buildingIds
                            ? getAddressByFirstBuildingId(manager.buildingIds)
                            : ''
                        }}
                        {{
                          manager.buildingIds && manager.buildingIds !== '[]'
                            ? '...'
                            : ''
                        }}
                      </base-table-cell>
                      <base-table-cell width="12.5%">{{
                        manager.active ? 'Активный' : 'Отключен'
                      }}</base-table-cell>
                      <!-- <base-table-cell width="15%"> -->
                      <!-- <mdicon
                    size="20"
                    name="check"
                    class="mr-3 green"
                    v-if="manager.receiveSMS"
                  ></mdicon> -->
                      <!-- <div
                          v-if="manager.receiveSMS"
                          class="mr-3 font-weight-bold"
                        >
                          Да
                        </div>
                        <div v-else class="mr-3 font-weight-bold">Нет</div> -->
                      <!-- <mdicon
                    size="20"
                    name="close"
                    class="mr-3 red"
                    v-else
                  ></mdicon> -->
                      <!-- </base-table-cell> -->
                      <base-table-cell width="8.5%">
                        <div class="d-flex justify-content-end w-100 actions">
                          <mdicon
                            size="17"
                            name="pencil"
                            class="actions__item mr-3"
                            @click="onManagerUpdate(manager)"
                          ></mdicon>
                          <mdicon
                            size="17"
                            name="delete"
                            class="actions__item"
                            @click="onManagerDelete(manager)"
                          ></mdicon>
                        </div>
                      </base-table-cell>
                    </base-table-row>
                  </template>
                </base-table>
              </div>
              <div class="managers__card-view" v-else>
                <card
                  v-for="(manager, idx) in managers"
                  :manager="manager"
                  :key="idx"
                  @update="onManagerUpdate(manager)"
                  @delete="onManagerDelete"
                ></card>
              </div>
            </div>
            <div v-else class="new-complex__wrapper">
              <div
                class="new-complex d-flex align-items-center justify-content-center"
                @click="setNewManagerPopupVisibility(true)"
              >
                <div
                  class="new-complex__plus d-flex align-items-center justify-content-center"
                >
                  +
                </div>
                <p class="new-complex__txt">Добавить менеджера</p>
              </div>
            </div>
          </slot>
        </div>
      </base-tab>
      <base-tab title="Администраторы">
        <div class="mt-3">
          <div v-if="isLoading" class="mt-20 text-center">
            <img
              :src="THEME === 'domion' ? loaderDomion : loaderGreen"
              alt="loading"
            />
          </div>
          <slot v-else>
            <div v-if="admins.length">
              <div v-if="view === 'list'" class="managers__list-view">
                <base-table>
                  <template v-slot:head>
                    <base-table-row>
                      <base-table-head-cell width="25%"
                        >Имя Фамилия</base-table-head-cell
                      >
                      <base-table-head-cell width="24.5%"
                        >Электронная почта</base-table-head-cell
                      >
                      <base-table-head-cell width="15%"
                        >Мобильный телефон</base-table-head-cell
                      >
                      <base-table-head-cell width="15%"
                        >ЖК</base-table-head-cell
                      >
                      <base-table-head-cell width="12.5%"
                        >Статус</base-table-head-cell
                      >
                      <!-- <base-table-head-cell width="15%"
                        >СМС информирование</base-table-head-cell
                      > -->
                      <base-table-head-cell width="8.5%"></base-table-head-cell>
                    </base-table-row>
                  </template>
                  <template v-slot:body>
                    <base-table-row v-for="admin in admins" :key="admin.id">
                      <base-table-cell width="25%" class="color-black"
                        >{{ admin.user.firstName }}
                        {{ admin.user.lastName }}
                      </base-table-cell>
                      <base-table-cell width="24.5%">{{
                        admin.user.email
                      }}</base-table-cell>
                      <base-table-cell width="15%">{{
                        admin.user.phone || ''
                      }}</base-table-cell>
                      <base-table-cell width="15%">
                        {{
                          admin.buildingIds
                            ? getAddressByFirstBuildingId(admin.buildingIds)
                            : ''
                        }}
                        {{
                          admin.buildingIds && admin.buildingIds !== '[]'
                            ? '...'
                            : ''
                        }}</base-table-cell
                      >
                      <base-table-cell width="12.5%">{{
                        admin.active ? 'Активный' : 'Отключен'
                      }}</base-table-cell>
                      <!-- <base-table-cell width="15%"> -->
                      <!-- <mdicon
                    size="20"
                    name="check"
                    class="mr-3 green"
                    v-if="manager.receiveSMS"
                  ></mdicon> -->
                      <!-- <div
                          v-if="admin.receiveSMS"
                          class="mr-3 font-weight-bold"
                        >
                          Да
                        </div>
                        <div v-else class="mr-3 font-weight-bold">Нет</div> -->
                      <!-- <mdicon
                    size="20"
                    name="close"
                    class="mr-3 red"
                    v-else
                  ></mdicon> -->
                      <!-- </base-table-cell> -->
                      <base-table-cell width="8.5%">
                        <div class="d-flex justify-content-end w-100 actions">
                          <mdicon
                            size="17"
                            name="pencil"
                            class="actions__item mr-3"
                            @click="onManagerUpdate(admin)"
                          ></mdicon>
                          <mdicon
                            size="17"
                            name="delete"
                            class="actions__item"
                            @click="onManagerDelete(admin)"
                          ></mdicon>
                        </div>
                      </base-table-cell>
                    </base-table-row>
                  </template>
                </base-table>
              </div>
              <div class="managers__card-view" v-else>
                <card
                  v-for="(admin, idx) in admins"
                  :manager="admin"
                  :key="idx"
                  @update="onManagerUpdate(admin)"
                  @delete="onManagerDelete"
                ></card>
              </div>
            </div>
            <div v-else class="new-complex__wrapper">
              <div
                class="new-complex d-flex align-items-center justify-content-center"
                @click="setNewManagerPopupVisibility(true)"
              >
                <div
                  class="new-complex__plus d-flex align-items-center justify-content-center"
                >
                  +
                </div>
                <p class="new-complex__txt">Добавить администратора</p>
              </div>
            </div>
          </slot>
        </div>
      </base-tab>
    </base-tabs-wrapper>
  </div>

  <manager-modal
    :manager="selectedManager"
    :employeeType="activeTab === 'Менеджеры' ? 'менеджера' : 'администратора'"
    :is-modal-visible="isNewManagerPopupVisible"
    @update="onManagerUpdated"
    @create="onManagerCreated"
    @close="setNewManagerPopupVisibility(false)"
  />
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import { useQuery, useLazyQuery, useMutation } from '@vue/apollo-composable';
import { getAllManagers, deleteManager } from '@/graphql/Managment.graphql';
import { getOneBuilding } from '@/graphql/Building.graphql';
import { BaseTabsWrapper, BaseTab, BaseButton } from '@/components/atoms/';
import ManagerModal from './ManagerModal';
import Card from './Card';
import {
  BaseTable,
  BaseTableCell,
  BaseTableHeadCell,
  BaseTableRow,
} from '@/components/BaseTable';

export default {
  name: 'managers-page',
  components: {
    Card,
    BaseTable,
    BaseTableRow,
    BaseTableCell,
    BaseTableHeadCell,
    BaseButton,
    ManagerModal,
    BaseTabsWrapper,
    BaseTab,
  },
  setup() {
    const isLoading = ref(true);
    const isNewManagerPopupVisible = ref(false);
    const selectedManager = ref(null);
    const view = ref('list');
    const managers = ref([]);
    const admins = ref([]);
    const loaderDomion = require('@/assets/images/loader.svg');
    const loaderGreen = require('@/assets/images/loader-green.svg');
    const THEME = config.VUE_APP_THEME;
    const activeTab = ref('Менеджеры');
    const buildingId = ref(1);
    const buildingAddresses = ref({});
    // const store = useStore();
    // const role = store.getters['app/userRole'];
    // console.log(role);

    const { mutate: deleteManagerMutation } = useMutation(deleteManager);
    const { refetch: fetchBuilding, result: buildingResult } =
      useQuery(getOneBuilding);

    const fetchAddressByBuildingId = async (buildId) => {
      await fetchBuilding({ id: buildId });

      return buildingResult.value.getOneBuilding.complex.name;
    };

    const loadBuildingAddresses = async () => {
      const buildingIdsSet = new Set();

      admins.value.forEach((admin) => {
        if (admin) {
          const buildingIdsArray = JSON.parse(admin.buildingIds) || [];
          if (buildingIdsArray.length && buildingIdsArray[0]) {
            buildingIdsSet.add(buildingIdsArray[0]);
          }
        }
      });

      managers.value.forEach((manager) => {
        if (manager) {
          const buildingIdsArray = JSON.parse(manager.buildingIds) || [];
          if (buildingIdsArray.length && buildingIdsArray[0]) {
            buildingIdsSet.add(buildingIdsArray[0]);
          }
        }
      });

      for (const id of buildingIdsSet) {
        const address = await fetchAddressByBuildingId(id);
        buildingAddresses.value[id] = address;
      }
    };

    const getAddressByFirstBuildingId = (buildingIds) => {
      const id = JSON.parse(buildingIds || '[]')[0];

      return id ? buildingAddresses.value[id] || '' : '';
    };

    const onSetView = (viewType) => {
      view.value = viewType;
    };

    const { result } = useQuery(getAllManagers, {
      employeeType: 'MANAGER',
    });

    const { result: adminResult } = useQuery(getAllManagers, {
      employeeType: 'ADMINUK',
    });

    watch(adminResult, (value) => {
      admins.value = value.getAllEmployeesByType;
      isLoading.value = false;
      loadBuildingAddresses();
    });

    watch(result, (value) => {
      managers.value = value.getAllEmployeesByType;
      isLoading.value = false;
      loadBuildingAddresses();
    });

    const onManagerModalClosed = () => {
      setNewManagerPopupVisibility(false);
    };

    const onManagerModalOpen = () => {
      setNewManagerPopupVisibility(true);
    };

    const setNewManagerPopupVisibility = (status) => {
      isNewManagerPopupVisible.value = status;

      if (!status) {
        selectedManager.value = null;
      }
    };

    const onManagerUpdate = (manager) => {
      selectedManager.value = manager;
      setNewManagerPopupVisibility(true);
    };

    const onManagerUpdated = (manager) => {
      if (activeTab.value === 'Менеджеры') {
        managers.value = managers.value.map((item) => {
          if (item.id === manager.id) {
            return { ...manager };
          }
          return item;
        });
      } else {
        admins.value = admins.value.map((item) => {
          if (item.id === manager.id) {
            return { ...manager };
          }
          return item;
        });
      }
    };

    const onManagerDelete = async (manager) => {
      const isManagerShouldBeDeleted = confirm(
        `Вы действительно хотите удалить менеджера ${manager.user.firstName} ${manager.user.lastName}`
      );
      if (isManagerShouldBeDeleted) {
        await deleteManagerMutation({
          id: manager.id,
        });

        if (activeTab.value === 'Менеджеры') {
          managers.value = managers.value.filter(
            (item) => item.id !== manager.id
          );
        } else {
          admins.value = admins.value.filter((item) => item.id !== manager.id);
        }
      }
    };

    const onManagerCreated = (manager) => {
      if (activeTab.value === 'Менеджеры') {
        managers.value = [...managers.value, manager];
      } else {
        admins.value = [...admins.value, manager];
      }
    };

    const handleTabClick = (tab) => {
      activeTab.value = tab;
    };

    return {
      isLoading,
      managers,
      admins,
      view,
      isNewManagerPopupVisible,
      selectedManager,
      onManagerDelete,
      onManagerCreated,
      onManagerUpdate,
      onSetView,
      onManagerModalOpen,
      setNewManagerPopupVisibility,
      onManagerModalClosed,
      onManagerUpdated,
      loaderDomion,
      loaderGreen,
      THEME,
      handleTabClick,
      activeTab,
      getAddressByFirstBuildingId,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_colors';

.new-complex {
  width: 300px;
  height: 150px;
  padding: 10px;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  background: var(--white-bg);
  border: 1px dashed var(--primary-range-color);
  flex-direction: column;
  border-radius: 10px;
  cursor: pointer;

  &__plus {
    width: 80px;
    height: 80px;

    font-size: 112px;
    font-weight: normal;
    color: var(--button-disable-color);
    border-radius: 40px;
    background: var(--primary-range-color);
    opacity: 0.6;
  }

  &__txt {
    position: relative;
    bottom: -14px;

    font-weight: bold;
    color: var(--primary-range-color);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
  }

  &:hover &__plus {
    opacity: 0.8;
  }
}

.results {
  &__view-switcher {
    cursor: pointer;
    color: map-get($primary-text, 'normal');
    padding: 4px;
    border-radius: 4px;
    box-shadow: 0 4px 4px var(--black-popup-shadow);

    &_active {
      cursor: default;
      color: map-get($primary-text, 'hover');

      box-shadow: 0 4px 4px var(--primary-light-opacity-color);
    }
  }

  &__title {
    color: map-get($black-text, 'normal');
    font-size: 18px;
    margin: 0;
    font-weight: 600;
  }
}

.managers {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__list-view {
    .actions {
      &__item {
        color: map-get($primary-text, 'default');
        cursor: pointer;
        padding: 5px;
        //transition: color .2s linear;

        &:hover {
          //color: map-get($primary-text, 'hover');
        }
      }
    }
  }

  &__card-view {
    display: grid;
    grid-template-columns: repeat(auto-fill, 1fr);
    gap: 20px;
  }
}
</style>

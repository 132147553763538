<template>
  <div class="setup-profile">
    <div>
      <div class="setup-profile__title">Настройте профиль</div>
      <form @submit.prevent="onFormSubmit" class="setup-profile__form">
        <div class="setup-profile__form-title">Установите пароль</div>
        <div class="mb-3">
          <base-input
            :value="form.password"
            label="Придумайте пароль*"
            placeholder="Ваш пароль"
            @input="onChangeInput($event, 'password')"
          />
        </div>
        <div class="mb-3">
          <base-input
            :value="form.secondPassword"
            label="Повторите пароль*"
            placeholder="Ваш пароль"
            @input="onChangeInput($event, 'secondPassword')"
          />
        </div>
        <div class="mb-3 h-10">{{ errorText }}</div>
        <base-button
          text="Сохранить"
          color="green"
          class="w-100"
          @click.prevent="onFormSubmit"
        />
      </form>
    </div>
  </div>
</template>
<script setup>
import { reactive } from 'vue';
import { BaseInput, BaseButton } from '@/components/atoms';
import { ref } from 'vue';

const form = reactive({
  password: '',
  secondPassword: '',
});
const errorText = ref('');

const onChangeInput = (e, type) => {
  form[type] = e.target.value;

  if (form.password === form.secondPassword) {
    errorText.value = '';
  } else {
    errorText.value = 'Пароли не совпадают';
  }
};

const onFormSubmit = () => {
  console.log('submit');
};
</script>
<style scoped lang="scss">
.setup-profile {
  padding-top: 50px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 700;
  }

  &__form {
    &-title {
      margin-bottom: 25px;
      font-size: 18px;
    }
  }
}
</style>

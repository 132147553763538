import PublicPagesController from '../controllers/PublicPagesController';

export default [
  {
    path: '/admin',
    name: 'LoginPage',
    component: PublicPagesController,
    meta: { public: true },
  },
  {
    path: '/client',
    name: 'LoginPageClient',
    component: PublicPagesController,
    meta: { public: true },
  },
  {
    path: '/client/message',
    name: 'MessagePage',
    component: PublicPagesController,
    meta: { public: true },
  },
  {
    path: '/setup',
    name: 'SetupProfilePage',
    component: PublicPagesController,
    meta: { public: true },
  },
  // {
  //   path: '/ory',
  //   name: 'OryPageClient',
  //   component: PublicPagesController,
  //   meta: { public: true },
  // },
  {
    path: '/forgot-password',
    name: 'ForgotPasswordPage',
    component: PublicPagesController,
    meta: { public: true },
  },
];
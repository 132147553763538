<template>
  <base-popup
    size="large"
    topIndent="30"
    :is-visible="isClaimModalVisible"
    :title="claim?.id !== null ? `№ ${currentClaim?.id} ` : 'Новая заявка'"
    :has-right-side-content="Boolean(claim?.id !== null)"
    :subtitle="
      claim?.id !== null
        ? `Квартира ${currentClaim?.apartment?.number} &#8226; ${new Date(
            currentClaim?.dateFrom
          ).toLocaleDateString('ru-RU')}`
        : `Квартира ${apartmentNumber} &#8226; ${new Date().toLocaleDateString(
            'ru-RU'
          )}`
    "
    @close="onClaimModalClose"
  >
    <template v-slot:right-side-content>
      <span
        v-if="claim?.id !== null"
        class="status"
        :class="statusClass(currentClaim.status)"
      >
        {{ getStatus(currentClaim.status) }}
      </span>
    </template>

    <div v-if="isLoading" class="claims__loader mt-20 text-center">
      <img
        :src="THEME === 'domion' ? loaderDomion : loaderGreen"
        alt="loading"
      />
    </div>
    <div v-else>
      <base-textarea
        placeholder="Ваш текст..."
        label="Описание*:"
        :value="currentClaim?.description"
        @input="onChangeDescription"
        maxLength="750"
        :readonly="onClaimClosed"
        :disabled="!isEditing && claim?.id !== null"
        :class="currentClaim?.description.length > 100 ? 'textarea--big' : ''"
        :count="currentClaim?.description.length"
      ></base-textarea>

      <div class="mt-3">
        <div class="claim-client-popup__attach">
          <TransitionGroup v-if="currentClaim">
            <div
              class="attach-images-item"
              v-for="(item, idx) in currentClaim?.attach"
              :key="idx"
            >
              <a target="_blank" :href="getImageUrl(item.file)">
                <img
                  :src="getImageUrl(item.file)"
                  class="claim-client-popup__attach-image"
                  alt="image"
                />
              </a>
              <span
                v-if="isEditing"
                class="attach-images-close"
                @click="
                  claim?.id !== null
                    ? deleteImageFromServer(item)
                    : onDeleteImage(item)
                "
              >
                <mdicon
                  size="20"
                  name="close"
                  class="attach-images-close-icon"
                />
              </span>
            </div>
          </TransitionGroup>

          <TransitionGroup v-if="attachFiles.length !== 0">
            <div
              class="attach-images-item"
              v-for="(item, idx) in attachFiles"
              :key="idx"
            >
              <img
                :src="item.file"
                class="claim-client-popup__attach-image"
                alt="image"
              />
              <span
                class="attach-images-close"
                @click="onDeleteAttachFile(idx)"
              >
                <mdicon
                  size="20"
                  name="close"
                  class="attach-images-close-icon"
                />
              </span>
            </div>
          </TransitionGroup>

          <claim-file-input
            v-if="
              isEditing &&
              attachFiles.length < 10 &&
              currentClaim.attach.length < 10
            "
            accept="image/png, image/jpeg"
            @file="onAttachFileChoose"
            :multiple="true"
          />
        </div>
        <div v-if="isEditing" class="popup__subtitle">
          {{ currentClaim.attach.length + attachFiles.length }}/10
        </div>
      </div>

      <claim-chat
        v-if="claim?.id !== null"
        :claim="claim"
        :isCreating="claim ? false : true"
      />

      <div class="wd-100 d-flex claim-client-popup__action">
        <div
          v-if="!isEditing && claim?.status === 'OPENED'"
          @click="isEditing = !isEditing"
          class="claim-client-popup__action-button"
        >
          <div class="claim-client-popup__action-button-icon">
            <mdicon
              size="20"
              name="square-edit-outline"
              class="claim-client-popup__action-button-icon-image"
            />
          </div>
          <div class="claim-client-popup__action-button-text">
            Редактировать
          </div>
        </div>

        <div v-else>
          <div
            @click="
              claim.id !== null && claim?.status === 'OPENED'
                ? onClientClaimDelete()
                : onClaimModalClose(true)
            "
            class="claim-modal-delete cursor-pointer"
            :class="
              claim.id !== null && claim?.status === 'OPENED'
                ? 'claim-client-popup__action-delete'
                : 'claim-client-popup__action-decline'
            "
          >
            {{
              claim.id !== null && claim?.status === 'OPENED'
                ? 'Удалить заявку'
                : 'Отмена'
            }}
          </div>
        </div>

        <base-button
          v-if="!isEditing && claim?.status === 'OPENED'"
          text="Готово"
          color="green"
          size="big"
          @click.prevent="onClaimModalClose(true)"
        />
        <base-button
          v-if="isEditing"
          :text="claim.id !== null ? 'Сохранить' : 'Создать'"
          color="green"
          size="big"
          @click.prevent="onFormSubmit"
        />
      </div>
    </div>
  </base-popup>

  <the-confirm
    :is-visible="isDeleteConfirmVisible"
    @close="onToggleDeleteConfirm"
    @confirm="isDeleteConfirmed"
  />
</template>

<script setup>
import { BasePopup, BaseTextarea, BaseButton } from '@/components/atoms';
import ClaimFileInput from './ClaimFileInput.vue';
import { statusClass } from '@/constant/status';
import { claimData } from '@/constant/claimData';
import TheConfirm from '@/components/TheConfirm.vue';
import { useMutation } from '@vue/apollo-composable';
import { computed, onMounted, ref, watch } from 'vue';
import {
  createRequestByClient,
  updateRequestByClient,
  deleteRequestByClient,
  deleteAttach,
} from '@/graphql/Claims.graphql';
import { useStore } from 'vuex';
import ClaimChat from './ClaimChat.vue';
import { logErrorMessages } from '@vue/apollo-util';
// import { THEME } from '@/constant/main'
import { linkGreen, linkDomion } from '@/constant/main';

const THEME = config.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const store = useStore();

const emit = defineEmits(['close', 'create', 'update', 'delete']);

const props = defineProps({
  isClaimModalVisible: { type: Boolean, default: true },
  claim: { type: Object, required: true },
  statuses: { type: Array, required: true },
});

// const isDomionHost = linkDomion.includes(window.location.host);
// const isGreenHost = linkGreen.includes(window.location.host);

let CLAIM_MEDIA_URL = localStorage.getItem('apiUrl');

// if (THEME === 'domion') {
//   CLAIM_MEDIA_URL = isDomionHost
//     ? `${config.VUE_APP_API_PROD}/request/attach`
//     : `${config.VUE_APP_API_DEV}/request/attach`;
// } else {
//   CLAIM_MEDIA_URL = isGreenHost
//     ? `${config.VUE_APP_API_PROD}/request/attach`
//     : `${config.VUE_APP_API_DEV}/request/attach`;
// }

// let user = store.getters['app/getUser'];
const apartmentId = ref();
const apartmentNumber = ref();

const currentClaim = ref(claimData);
const filesValueFile = ref([]);
const attachFiles = ref([]);
const attachFilesServer = ref([]);
const isDeleteConfirmVisible = ref(false);
const isClaimShouldBeDeleted = ref(false);
const isEditing = ref(true);
const isLoading = ref(false);

const { mutate: createRequestMutation, onError: errorCreateRequest } =
  useMutation(createRequestByClient);
const { mutate: updateRequestMutation, onError: errorUpdateRequest } =
  useMutation(updateRequestByClient);
const { mutate: deleteRequestMutation, onError: errorDeleteRequest } =
  useMutation(deleteRequestByClient);
const { mutate: deleteAttachMutation, onError: errorDeleteAttach } =
  useMutation(deleteAttach);

const onClaimClosed = computed(() => {
  return props.claim?.status === 'CLOSED' || props.claim?.status === 'REJECTED';
});

const onClaimModalClose = (isSubmit) => {
  isSubmit !== true ? (isSubmit = false) : (isSubmit = true);
  let isClosed = true;
  if (!isSubmit)
    isClosed = confirm(
      'Вы действительно хотите закрыть окно? Внесенные изменения не сохранятся'
    );
  if (isClosed) {
    emit('close');

    currentClaim.value = claimData;
    isEditing.value = true;
  }
};

const getStatus = (status) => {
  props.statuses.forEach((item) => {
    if (item.name === status) {
      status = item.title;
    }
  });
  return status;
};

const getImageUrl = (file) => {
  if (props.claim) {
    // // console.log("FILE", file);
    return file;
  } else {
    // // console.log('IMAGE', URL.createObjectURL(file));
    return URL.createObjectURL(file);
  }
};

const onAttachFileChoose = (files) => {
  const currentAttachLength = currentClaim.value?.attach.length || 0;

  const filesArray = Array.from(files);
  let limitedFilesArray;

  if (currentAttachLength === 0) {
    limitedFilesArray = filesArray.slice(0, 10);
  } else if (currentAttachLength < 10) {
    limitedFilesArray = filesArray.slice(0, 10 - currentAttachLength);
  } else {
    store.dispatch('notification/showNotification', {
      text: 'Превышен лимит загрузки файлов (10)',
      type: 'error',
    });
    return;
  }

  const filesList = limitedFilesArray.map((item) => {
    return {
      file: URL.createObjectURL(item),
    };
  });

  const filesListFile = limitedFilesArray.map((item) => {
    return {
      file: item,
    };
  });

  attachFilesServer.value = [...attachFilesServer.value, ...filesListFile];
  attachFiles.value = [...attachFiles.value, ...filesList];
  // // console.log(attachFiles.value, 'ATTACH FILES');
};

const onDeleteAttachFile = (index) => {
  attachFiles.value.splice(index, 1);
  attachFilesServer.value.splice(index, 1);
  // // console.log(attachFiles.value, "ATTACHED AFTER DELETE");
  URL.revokeObjectURL(index);
};

const onDeleteImage = async (item) => {
  const index = currentClaim.value.attach.indexOf(item);
  if (index !== -1) {
    currentClaim.value.attach.splice(index, 1);
  }
  // console.log('filesValueFile.value before', filesValueFile.value);
  const indexFile = filesValueFile.value.indexOf(item);
  if (indexFile !== -1) {
    filesValueFile.value.splice(indexFile, 1);
  }
  // console.log('filesValueFile.value after', filesValueFile.value);
  document.getElementById('upload-input');
};

const onLoadAttachFiles = async (requestId) => {
  isLoading.value = true;
  for (let i = 0; i < attachFilesServer.value.length; i++) {
    const { file } = attachFilesServer.value[i];
    const form = new FormData();
    form.append('requestId', requestId);
    form.append('files', file);

    await fetch(CLAIM_MEDIA_URL, {
      method: 'POST',
      header: {
        'Content-Type': 'multipart/form-data',
      },
      body: form,
    })
      .then((res) => res.json())
      .then((data) => () => {}) // console.log(data))
      .catch((err) => () => {}); // console.log(err));
  }
  isLoading.value = false;
};

const onChangeDescription = (e) => {
  currentClaim.value = {
    ...currentClaim.value,
    description: e.target.value,
  };
};

const deleteImageFromServer = async (item) => {
  const { data } = await deleteAttachMutation({
    id: item.id,
  });

  if (data.deleteAttach) {
    const index = currentClaim.value.attach.indexOf(item);

    if (index !== -1) {
      let attachedFiles = [...currentClaim.value.attach];
      attachedFiles.splice(index, 1);

      currentClaim.value = {
        ...currentClaim.value,
        attach: attachedFiles,
      };
    }
  }
};

errorDeleteAttach((error) => {
  store.dispatch('notification/showNotification', {
    text: `Ошибка удаления файла. ${error.message}`,
    type: 'error',
  });
  logErrorMessages(error);
});

const onClientClaimCreate = async () => {
  if (currentClaim.value.description.length === 0) {
    store.dispatch('notification/showNotification', {
      text: 'Поле описания не должно быть пустым',
      type: 'error',
    });

    return;
  }

  const { data } = await createRequestMutation({
    data: {
      description: currentClaim.value.description,
      apartmentId: apartmentId.value,
    },
  });

  // // console.log(data, 'DATA');
  // // console.log(isLoading.value, "BEFORE");
  await onLoadAttachFiles(data.createRequestByClient.id);
  // // console.log(isLoading.value, "AFTER");

  if (!isLoading.value) {
    const createdClaimData = {
      ...data.createRequestByClient,
      apartment: {
        id: apartmentId.value,
        number: apartmentNumber.value,
      },
      attach: [],
    };
    emit('create', createdClaimData);
    onClaimModalClose(true);

    store.dispatch('notification/showNotification', {
      text: 'Заявка успешно создана!',
      type: 'success',
    });
  }
};

errorCreateRequest((error) => {
  store.dispatch('notification/showNotification', {
    text: `Ошибка создания заявки. ${error.message}`,
    type: 'error',
  });
  logErrorMessages(error);
});

const onClientClaimUpdate = async () => {
  const { data } = await updateRequestMutation({
    id: currentClaim.value.id,
    description: currentClaim.value.description,
    apartmentId: apartmentId.value,
  });

  // console.log('update', data.updateRequestByClient);

  await onLoadAttachFiles(data.updateRequestByClient.id);

  emit('update', data.updateRequestByClient);
  onClaimModalClose(true);

  store.dispatch('notification/showNotification', {
    text: 'Заявка успешно сохранена!',
    type: 'success',
  });
};

errorUpdateRequest((error) => {
  store.dispatch('notification/showNotification', {
    text: `Ошибка изменения заявки. ${error.message}`,
    type: 'error',
  });
  logErrorMessages(error);
});

const onToggleDeleteConfirm = () => {
  isDeleteConfirmVisible.value = !isDeleteConfirmVisible.value;
};

const isDeleteConfirmed = () => {
  isClaimShouldBeDeleted.value = true;
};

const onClientClaimDelete = async () => {
  onToggleDeleteConfirm();
  watch(isClaimShouldBeDeleted, async (newValue) => {
    if (!newValue) {
      return;
    }

    const { id } = currentClaim.value;
    const { data } = await deleteRequestMutation({
      id,
    });

    // // console.log(data, 'DELETED DATA');

    store.dispatch('notification/showNotification', {
      text: `Заявка успешно удалена`,
      type: 'success',
    });

    emit('delete', id);
    isClaimShouldBeDeleted.value = false;
    isDeleteConfirmVisible.value = false;
    onClaimModalClose(true);
  });
};

errorDeleteRequest((error) => {
  store.dispatch('notification/showNotification', {
    text: `Ошибка удаления заявки. ${error.message}`,
    type: 'error',
  });
  logErrorMessages(error);
});

const onFormSubmit = () => {
  if (currentClaim.value.id === null) {
    onClientClaimCreate();
  } else {
    onClientClaimUpdate();
  }
};

watch(
  () => props.isClaimModalVisible,
  async () => {
    // // console.log(props.isClaimModalVisible, "V");
    if (props.isClaimModalVisible) {
      attachFiles.value = [];
      attachFilesServer.value = [];

      if (props.claim.id === null) {
        isEditing.value = true;
      }
    }
  }
);

watch(
  () => props.claim,
  async () => {
    // // console.log(props.claim, "PROPS");
    if (props.claim !== null) {
      isEditing.value = false;
      currentClaim.value = props.claim;
      // // console.log(currentClaim.value, 'GET ITEM');
    }
  }
);

onMounted(async () => {
  isLoading.value = false;
  let user = await store.dispatch('app/getUser');
  apartmentId.value = JSON.parse(user).ownership?.apartmentId;
  apartmentNumber.value = JSON.parse(user).ownership?.apartment.number;
});
</script>

<style scoped lang="scss">
.textarea--big {
  height: 200px;
}
.popup__subtitle {
  font-family: Golos;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  opacity: 0.5;
}
.attach-images-close {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: var(--white);
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s ease 0s;

  &-icon {
    color: var(--button-error-color);
  }
}

.attach-images-item {
  position: relative;
  margin-bottom: 10px;
}

.claim-client-popup {
  &__title {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    color: var(--black);
  }

  &__attach {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &-image {
      width: 100px;
      height: 100px;
      border-radius: 17px;
      object-fit: cover;
      // transform: scale(1);
    }
  }

  &__comments {
    margin-top: 20px;

    &-item {
      margin-bottom: 6px;
      padding: 14px;
      background: var(--grey-secondary-color);
      border-radius: 10px;
      box-sizing: border-box;
    }
  }

  &__action {
    margin-top: 30px;
    align-items: center;
    justify-content: space-between;

    &-button {
      display: flex;
      align-items: center;
      cursor: pointer;

      &-icon {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--primary-color);

        &-image {
          color: var(--white);
        }
      }

      &-text {
        font-size: 14px;
        font-weight: 500;
        color: var(--primary-color);
      }
    }

    &-delete {
      color: var(--button-error-color);
    }

    &-decline {
      color: var(--grey-color);
    }
  }
}
</style>

<template>
  <div v-if="isShowSidebar" class="sidebar">
    <div class="logo">
      <div class="logo__icon">
        <router-link to="/">
          <object
            type="image/svg+xml"
            :data="THEME === 'domion' ? logoSvg : logoGreen"
            width="42"
            :class="THEME === 'domion' ? 'logo__icon-svg' : ''"
          >
            Your browser does not support SVG
          </object>
        </router-link>
      </div>
      <div class="logo__content">
        <div
          class="logo__content-title"
          :style="THEME === 'domion' ? '' : 'color:black'"
        >
          {{ logoTitle }}
        </div>
        <div v-if="role === 'CLIENT'" class="logo__content-subtitle">
          Личный кабинет
        </div>
      </div>
    </div>

    <sidebar-select
      v-if="role === 'CLIENT'"
      :items="apartments"
      :chosen-item="apartments[0]"
    />
    <sidebar-select
      v-if="role !== 'CLIENT'"
      :items="tenants"
      :chosen-item="activeTenant"
      @chooseItem="onChooseItem"
    />
    <!-- ORY -->
    <!-- <sidebar-select v-if="role !== 'CLIENT'" :items="tenants" :chosen-item="tenants[0]" /> -->

    <div class="sidebar__links">
      <router-link
        v-for="(item, idx) in data"
        :key="idx"
        active-class="sidebar__item_active"
        :to="item.url"
        :class="[
          'sidebar__item',
          {
            'is-disabled': item.disabled,
          },
        ]"
      >
        <div class="sidebar__wrapper" @click="toggleChildren(item, data)">
          <div class="sidebar__item-icon">
            <mdicon :name="item.icon" />
          </div>
          <div class="sidebar__item-text">{{ item.name }}</div>
        </div>

        <div class="sidebar__subitems" v-if="item.isOpen">
          <router-link
            v-for="(subitem, idx) in item.items"
            :key="idx"
            :to="subitem.url"
            class="sidebar__subitem"
            active-class="sidebar__subitem_active"
          >
            <div class="sidebar__subitem-text">{{ subitem.name }}</div>
          </router-link>
        </div>
      </router-link>
    </div>
  </div>

  <!-- BURGER MENU -->
  <div
    class="sidebar__menu"
    :class="isShowBurgerMenu ? 'sidebar__menu--active' : ''"
  >
    <sidebar-select
      v-if="role === 'CLIENT'"
      :items="apartments"
      :chosen-item="apartments[0]"
    />
    <!-- ORY -->
    <!-- <sidebar-select v-if="role !== 'CLIENT'" :items="tenants" :chosen-item="tenants[0]" /> -->

    <div class="sidebar__links">
      <router-link
        v-for="(item, idx) in data"
        :key="idx"
        active-class="sidebar__item_active"
        :to="item.url"
        @click="closeBurgerMenu"
        :class="[
          'sidebar__item',
          {
            'is-disabled': item.disabled,
          },
        ]"
      >
        <div class="sidebar__wrapper" @click="toggleChildren(item, data)">
          <div class="sidebar__item-icon">
            <mdicon :name="item.icon" />
          </div>
          <div class="sidebar__item-text">{{ item.name }}</div>
        </div>

        <div class="sidebar__subitems" v-if="item.isOpen">
          <router-link
            v-for="(subitem, idx) in item.items"
            :key="idx"
            :to="subitem.url"
            class="sidebar__subitem"
            active-class="sidebar__subitem_active"
          >
            <div class="sidebar__subitem-text">{{ subitem.name }}</div>
          </router-link>
        </div>
      </router-link>
      <div @click="logout" class="sidebar__item">
        <div class="sidebar__wrapper">
          <div class="sidebar__item-icon">
            <mdicon name="logout" />
          </div>
          <div class="sidebar__item-text">Выйти</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';
import SidebarSelect from '@/components/atoms/components/SidebarSelect';
import { useStore } from 'vuex';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { getMyApartments } from '@/graphql/Login.graphql';
import { EventBus } from '@/utils/eventBus';
import { useRoute, useRouter } from 'vue-router';
import { oryLogIn } from '@/graphql/Login.graphql';
import { ory } from '@/config/ory';
import { computed } from 'vue';

const THEME = config.VUE_APP_THEME;
// TODO: import component
defineProps({
  data: { default: [] },
});

const logoSvg = require('@/assets/images/logo-domion.svg');
const logoGreen = require('@/assets/images/claims-green/inner-logo.svg');
const logoTitle = ref(
  config.VUE_APP_THEME === 'domion' ? 'домион' : 'ГРИН.ДОМ'
);
const store = useStore();
const router = useRouter();
const route = useRoute();
const role = store.getters['app/userRole'];
const apartments = ref([{ name: '' }]);

const session = localStorage.getItem('s');
const parsedSession = JSON.parse(session);
const tenants = ref(
  parsedSession ? parsedSession.identity?.metadata_public.tenants.available : []
);
const activeTenant = ref();
const isShowBurgerMenu = ref(false);

const { result: myApartmentsResult } = useQuery(getMyApartments);

const { mutate: oryLogInMutate } = useMutation(oryLogIn, () => ({
  variables: { data: {} },
}));

const oryLogin = async () => {
  const { data } = await oryLogInMutate();
  await store.dispatch('app/setAuth', {
    token: data.oryLogIn.token,
    role: data.oryLogIn.role,
    user: '{}',
  });
};

const isShowSidebar = computed(() => {
  return route.path.includes('setup-client') ? false : true;
});

// POST https://trouter.develop.k8s.cirus-it.com/tenant/set-active
// content-type: application/json

// {
//   "identityId": "01089780-255f-4b9a-bbbf-1e94e0158545",
//   "tenantId": "dd"
// }

const onChooseItem = async (item) => {
  if (item) {
    const identity = localStorage.getItem('s');
    const identityId = JSON.parse(identity).identity.id;
    const url = `${config.VUE_APP_API_TROUTER}/tenant/set-active`;

    const data = {
      identityId: identityId,
      tenantId: item.id,
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      redentials: 'include',
    };

    await fetch(url, options)
      .then((response) => response.json())
      .then((data) => () => {})
      .catch((error) => () => {});

    localStorage.setItem('apiUrl', item.apiUrl);
    window.location.reload();
    await oryLogin();
    window.location.reload();
  }
};

const logout = async () => {
  let logoutLink = localStorage.getItem('logout-url');
  await store.dispatch('app/logout');
  if (logoutLink) {
    let newWindow = window.open(logoutLink, '_blank');

    setTimeout(function () {
      newWindow.close();
      router.push('/');
    }, 500);
  } else router.push('/');
};

const toggleChildren = (item, data) => {
  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    element.isOpen = false;
  }
  item.isOpen = !item.isOpen;
};

function renameAddressKeyToName(arr) {
  return arr.map((item) => {
    const { address, ...rest } = item;
    if ('address' in item) {
      return { name: address, ...rest };
    }
    return item;
  });
}

const showBurgerMenu = () => {
  isShowBurgerMenu.value = !isShowBurgerMenu.value;
};

const closeBurgerMenu = () => {
  EventBus.emit('close-burger');
  isShowBurgerMenu.value = !isShowBurgerMenu.value;
};

watch(myApartmentsResult, (value) => {
  apartments.value = renameAddressKeyToName(value.getMyApartments);
});

const getActiveTenant = async () => {
  const { data } = await ory.toSession();
  await store.dispatch('app/setSession', data);
  const storedData = store.getters['app/getSession'];

  if (!storedData) return;

  const tenants = storedData.identity.metadata_public.tenants;
  const activeTenant = tenants.active;
  const tenantInfo = tenants.available.find(
    (tenant) => tenant.id === activeTenant
  );

  if (!tenantInfo) return;

  return tenantInfo;
};

onMounted(async () => {
  EventBus.on('show-burger', () => showBurgerMenu());
  // console.log(tenants.value, 'ten');
  if (tenants.value) {
    activeTenant.value = await getActiveTenant();
  }
});

onUnmounted(() => {
  EventBus.off('show-burger', () => showBurgerMenu());
});
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/_colors';

.sidebar {
  padding: 0 0 16px 0;
  height: auto;
  width: 255px;
  font-family: Golos;
  background: map-get($sidebar, 'bg');
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: block;
  }

  &__menu {
    position: fixed;
    top: 88px;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    transform: translateY(300%);
    transition: transform 0.5s ease;
    z-index: 1000;

    &--active {
      transition: transform 0.5s ease;
      transform: translateY(0);
    }
  }

  .logo {
    margin-left: 16px;
    display: flex;
    align-items: center;

    &__icon {
      margin-right: 15px;
      display: flex;
      align-items: center;
      height: 88px;

      &-svg {
        filter: var(--primary-svg);
      }
    }

    &__content {
      color: var(--primary-color);
      font-weight: 700;

      &-title {
        margin-bottom: 5px;
        font-size: 24px;
      }

      &-subtitle {
        font-size: 14px;
      }
    }
  }

  &__links {
    margin-top: 24px;
    padding-left: 16px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    padding: 18px 14px;
  }

  &__item {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    color: map-get($primary-text, 'normal');
    margin-bottom: 16px;

    &:hover,
    &_active {
      color: map-get($primary-text, 'hover');
      cursor: pointer;
      background-color: var(--white);
    }

    &-icon {
      margin-right: 10px;
    }

    &-text {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
    }

    &.is-disabled {
      opacity: 0.4;
      pointer-events: none;
      cursor: default;
    }
  }

  &__subitems {
    display: flex;
    flex-direction: column;
  }

  &__subitem {
    display: flex;
    flex-direction: column;
    padding: 7px 0 11px 48px;
    text-decoration: none;
    color: map-get($primary-text, 'normal');

    &:hover,
    &_active {
      background-color: var(--primary-light-opacity-color);
    }

    &-text {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
  }
}

@media (max-width: 1000px) {
  .sidebar {
    display: none;
  }
}
</style>
